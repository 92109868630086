import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import ProfileImg from "@icarius-common/profileImg";
import { VariableSizeList as List } from 'react-window';
import { getUserData } from "src/app/selectors";

const UserSection = React.memo((props) => {
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const {
    color,
    searchValue,
    employees,
    handleUserClick,
    selectedEmployee,
  } = props;

  const userData = useSelector(getUserData);
  const userTasks = selectedEmployee || userData.code;

  const matchesMoreOrEqualThan960 = useMediaQuery(`(min-width:960px)`);
  const matchesLessThan600 = useMediaQuery(`(max-width:600px)`);

  useEffect(() => {
    // para el input de la segunda columna, si es la primera columna, no filtra y queda una copia de employees
    let filteredEmployeesAux = [...employees];

    if (filteredEmployeesAux && filteredEmployeesAux.length > 0 && searchValue && searchValue !== "") {
      filteredEmployeesAux = filteredEmployeesAux.filter((employee) =>
        employee.name && employee.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    }


    setFilteredEmployees(filteredEmployeesAux);
  }, [searchValue, employees])

  const Row = ({ index, style }) => (
    <div style={style}>
      <Grid
        container alignItems="center" key={filteredEmployees[index].key}
        style={
          filteredEmployees[index].key === userTasks
            ? { cursor: "pointer", backgroundColor: `${color}`, height: 60, marginBottom: 10 }
            : { cursor: "pointer", height: 60, marginBottom: 10 }
        }
        onClick={() => handleUserClick(filteredEmployees[index].key)}
      >
        <Grid item xs={2} style={{ marginLeft: 10 }}>
          <ProfileImg
            image={Boolean(filteredEmployees[index]) ?
              filteredEmployees[index]["FOTOGRAFIA"] || filteredEmployees[index]["img"]
              : ""}
            size={matchesLessThan600 ? 30 : 40}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h6" style={filteredEmployees[index].key === userTasks ? { color: "white", fontWeight: 100, fontSize: matchesLessThan600 ? 14 : 16 } : { color: "var(--mainText)", fontWeight: 100, fontSize: matchesLessThan600 ? 14 : 16 }}>
            {filteredEmployees[index].name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <List
      style={{ overflowX: "hidden" }}
      className="List"
      height={matchesLessThan600 ? 150 : 250}
      itemCount={filteredEmployees.length}
      itemSize={() => 60}
      width={matchesMoreOrEqualThan960 && '100%'}
    >
      {Row}
    </List>
  )
})

export default UserSection;
