import React from 'react';
import { IconButton, ListItem, Input } from '@material-ui/core';
import { DeleteIcon } from "@icarius-icons";
import clsx from 'clsx';

function TagFormListItem(props) {
    function handleChange(event) {
        props.onListItemChange({ labelCode: props.item.labelCode, nombre: event.target.value });
    }

    function handleBlur(event) {
        props.onBlur({ labelCode: props.item.labelCode, nombre: event.target.value });
    }

    if (!props.item) {
        return null;
    }
    
    return (
        <ListItem className="p-0" dense>
            <Input
                className={clsx("flex flex-1 mx-8")}
                name="text"
                value={props.item.nombre}
                onChange={handleChange}
                onBlur={handleBlur}
                disableUnderline
            />
            <IconButton className="w-32 h-32 mx-4 p-0" aria-label="Delete" onClick={() => props.onListItemRemove(props.item.labelCode)}>
                <DeleteIcon fontSize="small" />
            </IconButton>
        </ListItem>
    );
}

export default TagFormListItem;
