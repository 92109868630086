import React from 'react';
import { List } from '@material-ui/core';
import TagFormListItem from './TagFormListItem';
import TagFormAddListItem from './TagFormAddListItem';

function TagFormList(props) {
    function handleListItemChange(item) {
        props.onCheckListChange(props.tags.map((_item) => _item.labelCode === item.labelCode ? item : _item));
    }

    function handleListItemRemove(labelCode) {
        props.onCheckListChange(props.tags.filter((_item) => _item.labelCode !== labelCode));
        props.removeItem(labelCode)
    }

    function handleListItemAdd(item) {
        return new Promise(function (resolve, reject) {
            props.addItem(item).then(resp => {
                if (resp.data.status === "OK") {
                    let list = [...props.tags];
                    list.unshift(resp.data.label.data)
                    props.onCheckListChange(list)
                }
                return resolve(resp)
            })
        })
    }

    return (
        <div className={props.className}>
            <List dense style={{ padding: 0 }}>
                <TagFormAddListItem onListItemAdd={handleListItemAdd} />
                <div id="tag-list" style={{ maxHeight: "50vh", overflowY: "auto" }}>
                    {
                        props.tags.map((item, i) => (
                            <TagFormListItem
                                item={item}
                                key={i}
                                onListItemChange={handleListItemChange}
                                onListItemRemove={handleListItemRemove}
                                onBlur={props.editItem}
                                oldValue={item.nombre}
                            />
                        ))
                    }
                </div>
            </List>
        </div>
    );
}

export default TagFormList;
