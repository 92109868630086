import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Grid, Typography, DialogActions } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ProfileImg from "@icarius-common/profileImg";
import SubitemContainer from "./SubitemContainer";
import AddCheckListItem from './AddCheckListItem';
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import moment from "moment";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import DialogTransition from "@icarius-common/dialogTransition";

const SubitemListDialog = (props) => {

  const {
    open,
    subitems,
    itemTitle,
    isOwner,
    isResponsible,
    parentIsDone,
    parentEndDate,
    responsibleName,
    responsibleKey,
    profileImg,
    handleClose,
    handleSubmit,
    isShared
  } = props;

  const [subitemsToModify, setSubitemsToModify] = useState(subitems || []);

  const isSubtaskDisabled = !isResponsible && !isOwner;

  const getSortedSubitems = (subitems) => {
    subitems.sort((a, b) => moment(createDateFromDDMMYYYY(a.endDate)) - moment(createDateFromDDMMYYYY(b.endDate)))
    return subitems.map((subitem, index) => ({ ...subitem, index }))
  }

  const handleSubmitUsers = () => {
    handleSubmit(subitemsToModify.length > 0 ? subitemsToModify : []);
    handleClose();
  }

  const handleChange = (item) => {
    let newSubitems = [...subitemsToModify];
    newSubitems[item.index] = item;
    getSortedSubitems(newSubitems)
    setSubitemsToModify(newSubitems);
  }

  const handleListItemRemove = (label) => setSubitemsToModify(prevSubitemsToModify => prevSubitemsToModify.filter((item) => item.label !== label));

  const handleListItemAdd = (item) => {
    let arrModifiedSubitems = [...subitemsToModify, item];
    setSubitemsToModify(getSortedSubitems(arrModifiedSubitems));
  };
  
  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("subTasks")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="row" justify="center" style={{ paddingBottom: 10 }}>
          <Typography variant={"h6"} className={"whiteText"} style={{ width: "80%", overflowWrap: "anywhere", textAlign: "center" }}>
            {itemTitle}
          </Typography>
        </Grid>
        {
          isShared &&
          <Grid container direction="row" justify="center" alignItems="center">
            <ProfileImg image={profileImg} size={40} />
            <Typography variant={"subtitle1"} className={"whiteText"} style={{ paddingLeft: 20 }}>
              {responsibleName || getLocalizedString("notAssigned")}
            </Typography>
          </Grid>
        }
        <Grid container style={{ paddingTop: 10 }}>
          {
            subitemsToModify.length > 0 ?
              <Typography variant={"caption"} className={"whiteText"} style={{ padding: 10, fontSize: "0.85rem" }}>
                {getLocalizedString("subitemEndDateWarning")}
              </Typography>
              : null
          }
          {
            subitemsToModify.map((subitem, index) =>
              <SubitemContainer
                key={index}
                handleListItemChange={handleChange}
                handleListItemRemove={handleListItemRemove}
                index={index}
                subitem={subitem}
                isOwner={isOwner}
                isResponsible={isResponsible}
                parentIsDone={parentIsDone}
                parentEndDate={parentEndDate}
              />
            )}
          {
            subitemsToModify.length === 0 &&
            <Typography variant={"h6"} className={"whiteText"} style={{ paddingTop: 10, fontWeight: 100 }}>
              {getLocalizedString("noSubtasks")}
            </Typography>
          }
          {
            !parentIsDone && !isSubtaskDisabled &&
            <AddCheckListItem onListItemAdd={handleListItemAdd} responsibleKey={responsibleKey} />
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={handleClose}
          text={getLocalizedString(!parentIsDone && !isOwner && !isResponsible ? "close" : "disagree")}
          isAccept={!parentIsDone && !isOwner && !isResponsible}
        />
        {
          !parentIsDone && (isOwner || isResponsible) &&
          <ButtonDialogAction onClick={handleSubmitUsers} isAccept text={getLocalizedString("save")} />
        }
      </DialogActions>
    </Dialog>
  );
}

export default SubitemListDialog;
