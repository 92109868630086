import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  userLabels: [],
  notes: [],
  commentsForNote: [],
  retrievingCommentsForNote: false,
  retrievingSocietyPeopleAndGroups: false,
  groups: [],
  societyPeople: [],
  dateFormat: "dd/mm/yyyy",
  sendingMail: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_COMMENTS_FOR_NOTE_REJECTED:
      return { ...state, retrievingCommentsForNote: false };
    case actionTypes.GET_COMMENTS_FOR_NOTE_FULFILLED:
      return { ...state, retrievingCommentsForNote: false, commentsForNote: action.payload.comments };
    case actionTypes.GET_COMMENTS_FOR_NOTE:
      return { ...state, retrievingCommentsForNote: true };
    case actionTypes.GET_SOCIETY_AND_GROUPS:
      return { ...state, retrievingSocietyPeopleAndGroups: true };
    case actionTypes.GET_SOCIETY_AND_GROUPS_FULFILLED:
      return { ...state, retrievingSocietyPeopleAndGroups: false, groups: action.payload.groups, societyPeople: action.payload.societyPeople };
    case actionTypes.GET_SOCIETY_AND_GROUPS_REJECTED:
      return { ...state, retrievingSocietyPeopleAndGroups: false };
    case actionTypes.GET_LABELS:
      return { ...state, loading: true };
    case actionTypes.GET_LABELS_FULFILLED: {
      return { ...state, userLabels: action.payload.labels, loading: false }
    }
    case actionTypes.GET_LABELS_REJECTED:
      return { ...state, loading: false };
    case actionTypes.GET_NOTES:
      return { ...state, loading: true };
    case actionTypes.GET_NOTES_FULFILLED:
      return {
        ...state,
        notes: action.payload.notes,
        dateFormat: action.payload.dateFormat,
        loading: false,
      }
    case actionTypes.GET_NOTES_REJECTED:
      return { ...state, loading: false };
    case actionTypes.CREATE_NOTE:
      return { ...state, loading: true };
    case actionTypes.CREATE_NOTE_FULFILLED: {

      let newNotes = [...state.notes];
      newNotes.unshift(action.payload.data);

      return { ...state, notes: newNotes, loading: false }
    }
    case actionTypes.CREATE_NOTE_REJECTED:
      return { ...state, loading: false };
    case actionTypes.DELETE_NOTE:
      return { ...state, loading: true };
    case actionTypes.DELETE_NOTE_FULFILLED: {
      let finalNoteArray = [...state.notes].filter(
        field => field["codNota"] !== action.payload.removedNote["codNota"]
      );
      return { ...state, notes: finalNoteArray, loading: false }
    }
    case actionTypes.DELETE_NOTE_REJECTED:
      return { ...state, loading: false };
    case actionTypes.EDIT_NOTE:
      return { ...state, loading: true };
    case actionTypes.EDIT_NOTE_FULFILLED: {
      if (action.payload.data.status === "OK") {
        const data = action.payload.data.data;
        let newNotes = [...state.notes];
        let modifiedItem = state.notes.find(item => item.codNota === (data && data.codNota));

        const index = state.notes.indexOf(modifiedItem);
        if (index > -1) {
          newNotes.splice(index, 1);
        }
        modifiedItem["Fecha de actualizacion"] = data && data["Fecha de actualizacion"];
        modifiedItem["Hora de actualizacion"] = data && data["Hora de actualizacion"];
        modifiedItem["Fecha de revision"] = data && data["Fecha de revision"];
        modifiedItem.accepted = data && data.accepted;
        modifiedItem.archiv = data && data.archiv;
        modifiedItem.attachments = data && data.attachments;
        modifiedItem.checklist = data && data.checklist;
        modifiedItem.codNota = data && data.codNota;
        modifiedItem.editable = data && data.editable;
        modifiedItem.img = data && data.img;
        modifiedItem.labels = data && data.labels;
        modifiedItem.lastEditor = data && data.lastEditor;
        modifiedItem.numberOfComments = data && data.numberOfComments;
        modifiedItem.owner = data && data.owner;
        modifiedItem.ownerName = data && data.ownerName;
        modifiedItem.shared = data && data.shared;
        modifiedItem.sharedWith = data && data.sharedWith;
        modifiedItem.titulo = data && data.titulo;
        modifiedItem.texto = data && data.texto;
        modifiedItem.feedback = data && data.feedback;

        newNotes.unshift(modifiedItem);

        return { ...state, notes: newNotes, loading: false }
      } else { return { ...state, loading: false } }
    }
    case actionTypes.EDIT_NOTE_REJECTED:
      return { ...state, loading: false };

    case actionTypes.CREATE_LABEL:
      return { ...state, loading: true };
    case actionTypes.CREATE_LABEL_FULFILLED: {
      let newLabels = [...state.userLabels];
      newLabels.unshift(action.payload.data);

      return { ...state, userLabels: newLabels, loading: false }
    }
    case actionTypes.CREATE_LABEL_REJECTED:
      return { ...state, loading: false };
    case actionTypes.DELETE_LABEL:
      return { ...state, loading: true };
    case actionTypes.DELETE_LABEL_FULFILLED: {
      let finalLabelsArray = [...state.userLabels].filter(
        field => field.labelCode !== action.payload.data
      );
      return { ...state, userLabels: finalLabelsArray, loading: false }
    }
    case actionTypes.DELETE_LABEL_REJECTED:
      return { ...state, loading: false };
    case actionTypes.EDIT_LABEL:
      return { ...state, loading: true };
    case actionTypes.EDIT_LABEL_FULFILLED: {
      const data = action.payload.data;
      let newLabels = [...state.userLabels];
      let modifiedItem = state.userLabels.find(item => item.labelCode === (data && data.labelCode));
      const index = state.userLabels.indexOf(modifiedItem);

      if (index > -1) {
        modifiedItem.labelCode = data && data.labelCode;
        modifiedItem.nombre = data && data.nombre;

        newLabels[index] = modifiedItem;
      }
      return { ...state, userLabels: newLabels, loading: false }
    }
    case actionTypes.EDIT_LABEL_REJECTED:
      return { ...state, loading: false };
    case actionTypes.ADD_NEW_COMMENT_FULFILLED: {
      let newCommentsForNote = [...state.commentsForNote];
      newCommentsForNote.push(action.payload.comment);
      return { ...state, commentsForNote: newCommentsForNote }
    }
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const newCommentsForNote = [...state.commentsForNote].filter(e => e.code !== action.payload.code);
      return { ...state, commentsForNote: newCommentsForNote }
    }
    case actionTypes.CLEAR_COMMENTS:
      return { ...state, commentsForNote: [] }
    case actionTypes.REFUSE_NOTE_FULFILLED:
      {
        let newNotes = [...state.notes];
        const index = state.notes.indexOf(action.payload.note);
        if (index > -1) {
          newNotes.splice(index, 1);
        }

        return { ...state, notes: newNotes }
      }
    case actionTypes.ACCEPT_NOTE_FULFILLED:
      {
        let newNotes = [...state.notes];
        let modifiedItem = state.notes.find(item => item.codNota === (action.payload.note && action.payload.note.codNota));

        const index = state.notes.indexOf(action.payload.note);

        if (index > -1) {
          modifiedItem.accepted = "Y";

          newNotes[index] = modifiedItem;
        }

        return { ...state, notes: newNotes }
      }
    case actionTypes.EDIT_COMMENT_FULFILLED: {
      const data = action.payload.comment;
      let newComments = [...state.commentsForNote];
      let modifiedItem = state.commentsForNote.find(item => item.code === (data && data.code));
      const index = state.commentsForNote.indexOf(modifiedItem);
      if (index > -1) {
        modifiedItem.author = data.author;
        modifiedItem.code = data.code;
        modifiedItem.date = data.date;
        modifiedItem.time = data.time;
        modifiedItem.text = data.text;
        modifiedItem.noteCode = data.noteCode;
        newComments[index] = modifiedItem;
      }
      return { ...state, commentsForNote: newComments }
    }
    case actionTypes.SHARE_NOTE_FULFILLED: {
      const data = action.payload.note;

      let newNotes = [...state.notes];
      let modifiedItem = state.notes.find(item => item.codNota === (data && data.codNota));

      const index = state.notes.indexOf(modifiedItem);
      if (index > -1) {
        newNotes.splice(index, 1);
      }
      modifiedItem["Fecha de actualizacion"] = data && data["Fecha de actualizacion"];
      modifiedItem["Hora de actualizacion"] = data && data["Hora de actualizacion"];
      modifiedItem["Fecha de revision"] = data && data["Fecha de revision"];
      modifiedItem.accepted = data && data.accepted;
      modifiedItem.archiv = data && data.archiv;
      modifiedItem.attachments = data && data.attachments;
      modifiedItem.checklist = data && data.checklist;
      modifiedItem.codNota = data && data.codNota;
      modifiedItem.editable = data && data.editable;
      modifiedItem.img = data && data.img;
      modifiedItem.labels = data && data.labels;
      modifiedItem.lastEditor = data && data.lastEditor;
      modifiedItem.numberOfComments = data && data.numberOfComments;
      modifiedItem.owner = data && data.owner;
      modifiedItem.ownerName = data && data.ownerName;
      modifiedItem.shared = data && data.shared;
      modifiedItem.sharedWith = data && data.sharedWith;
      modifiedItem.titulo = data && data.titulo;
      modifiedItem.texto = data && data.texto;

      newNotes.unshift(modifiedItem);

      return { ...state, notes: newNotes }
    }
    case actionTypes.SEND_TASK_REMINDER:
      return { ...state, sendingMail: true }
    case actionTypes.SEND_TASK_REMINDER_FULFILLED:
      return { ...state, sendingMail: false }
    case actionTypes.SEND_TASK_REMINDER_REJECTED:
      return { ...state, sendingMail: false }

    default:
      return state;
  }
}
