import React from 'react';
import { Typography, Grid, MenuItem } from '@material-ui/core';
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { getLocalizedString } from "@icarius-localization/strings";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { updateNoteCommentAction, deleteNoteCommentAction } from "../../actions";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { getAppColor } from "src/app/selectors";
import DotsMenu from "@icarius-common/dotsMenu";

class CommentMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isModifying: false };
    }

    handleOpenEditDialog = (code) => {
        this.setState({ isModifying: true })
    }

    handleDelete = (code) => {
        this.props.dispatch(deleteNoteCommentAction(code))
    }
    
    handleGenerate = values => {
        const { comment } = this.props;
        // Si tengo algo, entonces lo mando
        if (values.comment && values.comment !== "" && comment.code) {
            this.props.dispatch(updateNoteCommentAction(comment.code, values.comment)).then(r => r.data.status === "OK" && this.setState({ isModifying: false }));
            window.setFormValue("comment", "");
        }
    }

    render() {
        const { comment, author, color, isAuthor } = this.props;

        let initialValues = { comment: "" }
        if (this.state.isModifying) {
            initialValues.comment = comment.text;
        }
        return <Grid container item xs={12} style={{ paddingBottom: 20 }}>
            <Grid container item xs={12}>
                <Grid container alignItems="center" item xs={12}>
                    <Grid item xs={2}>
                    {
                        author && author["FOTOGRAFIA"] && author["FOTOGRAFIA"] !== "-" && author["FOTOGRAFIA"] !== "" ?
                            <img
                                src={IMAGES_ENDPOINT + author["FOTOGRAFIA"]}
                                onError={event => event.target.src = DefaultImage}
                                alt={comment.author}
                                className={"employee-image"}
                                style={{ height: 50, width: 50 }}
                            />
                            :
                            <div className={"employee-image"} style={{ background: color }} />
                    }
                    </Grid>
                    <Grid item xs={9} style={{ paddingLeft: 20 }}>
                        <Typography className="text-14 ml-auto mr-4 whiteText" style={{ fontWeight: 400 }}>
                            {comment.author}
                        </Typography>
                    </Grid>
                    {
                        isAuthor &&
                        <Grid container direction="row" justify="flex-end" item xs={1}>
                            <DotsMenu>
                                <MenuItem onClick={() => this.handleOpenEditDialog(comment.code)}>
                                    {getLocalizedString("edit")}
                                </MenuItem>
                                <MenuItem onClick={() => this.handleDelete(comment.code)}>
                                    {getLocalizedString("delete")}
                                </MenuItem>
                            </DotsMenu>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                this.state.isModifying === false &&
                <Grid container item xs={12}>
                    <Grid item xs={2} />
                    <Grid item xs={9} style={{ paddingLeft: 20 }}>
                        <Typography className="text-12 ml-auto mr-4 whiteText" style={{ fontWeight: 400, textAlign: "justify", wordBreak: "break-word" }}>
                            {comment.text}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={9} style={{ paddingTop: 5, paddingLeft: 20 }}>
                        <Typography className="text-14 ml-auto mr-4 whiteText" style={{ fontWeight: 300 }}>
                            {`${comment.date} ${comment.time}`}
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                isAuthor && this.state.isModifying === true &&
                <Grid container item xs={12} style={{ marginTop: -25 }}>
                    <Grid item xs={12} style={{ marginTop: 35 }}>
                        <Form
                            mutators={{
                                setValue: ([field, value], state, { changeValue }) => {
                                    changeValue(state, field, () => value);
                                },
                            }}
                            onSubmit={this.handleGenerate}
                            initialValues={initialValues}
                            render={({ handleSubmit, form, submitting, pristine, values, active }) => {
                                window.setFormValue = form.mutators.setValue;
                                return <form onSubmit={handleSubmit} noValidate>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="comment"
                                                margin={"none"}
                                                multiline={true}
                                                rows={2}
                                                placeholder={getLocalizedString("writeHereYourComment")}
                                            />
                                        </Grid>
                                        <Grid container item justify="flex-end" xs={12} style={{ paddingTop: 10 }}>
                                            <ButtonDialogAction
                                                type="submit"
                                                isAccept
                                                text={getLocalizedString("editCommentAction")}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            }} />
                    </Grid>
                </Grid>
            }
        </Grid>
    }

}

export default connect(
    createStructuredSelector({
        color: getAppColor,
    })
)(CommentMobile);
