import React from 'react';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import { DeleteForeverIcon } from "@icarius-icons";
import { RESOURCES_ENDPOINT } from '@icarius-connection/endpoints';

const NoteAttachment = (props) => {
    const { attachments, attachment, className, onDelete, onClick } = props;
    const label = attachment.split('-_-')[1];
    const attachmentURL = RESOURCES_ENDPOINT + attachment;
    return (
        <Chip
            label={label}
            classes={{
                root: clsx("h-24 br-6 label-width", className),
                label: "px-6 py-4",
                deleteIcon: "w-16 ml-0 whiteText",
            }}
            className="whiteText"
            variant="outlined"
            style={{ borderColor: "var(--modalBg)", textDecoration: "underline" }}
            onClick={attachment.includes("notes/attachments") && onClick ? () => onClick(attachmentURL) : undefined}
            onDelete={onDelete ? () => onDelete(attachments, attachment) : undefined}
            deleteIcon={<DeleteForeverIcon className="whiteText" />}
        />
    );
}

export default NoteAttachment;