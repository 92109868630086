import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useSelector } from "react-redux";
import Comment from "./comment";
import CommentMobile from "./commentMobile";
import { getUserData } from "src/app/selectors";
import { XS_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";

const CommentHistory = (props) => {

    const userData = useSelector(getUserData);
    const { comments, societyPeople } = props;

    const useFullSizeCommentBox = useMediaQuery(`(max-width:${XS_DEVICE_WIDTH_BREAKPOINT}px)`); 

    return <Grid container>
        {
            comments.map(comment => {
                const author = societyPeople.find(e => e.name === comment.author);
                return <Grid container direction="column" key={comment.code} style={{ paddingTop: 26 }}>
                    <Grid item xs={12}>
                        {
                            useFullSizeCommentBox ?
                            <CommentMobile comment={comment} author={author} isAuthor={userData?.code === author?.key} />
                            : <Comment comment={comment} author={author} isAuthor={userData?.code === author?.key} />
                        }
                    </Grid>
                </Grid>
            }
        )}
    </Grid>
}

export default CommentHistory;
