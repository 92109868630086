import React from 'react';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import { AccessTimeIcon } from "@icarius-icons";

const NoteReminderLabel = (props) => {

    const { date, className } = props;

    if (!date) {
        return null;
    }    

    return (
        <Chip
            icon={<AccessTimeIcon className="text-16 mr-0 whiteText" />}
            label={typeof date === "object" ? moment(props.date).format("DD/MM/YYYY") : date}
            className="whiteText"
            classes={{
                root: clsx("h-24 br-6", className),
                label: "pl-4 pr-6 py-4 text-11",
                outlined: "whiteText-border",
                deleteIcon: "w-16 ml-0",
            }}
            variant="outlined"
        />
    );
}

export default NoteReminderLabel;
