import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { getLocalizedString } from "@icarius-localization/strings";
import { getCommentsForNote } from "../../selectors";
import { getNoteCommentsAction, clearCommentsAction } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import CommentHistory from "./commentHistory"
import NewCommentBox from "./newCommentBox";

const CommentsBox = (props) => {
    
    const dispatch = useDispatch();
    const comments = useSelector(getCommentsForNote);
    const { note, societyPeople } = props;

    useEffect(() => {
        dispatch(clearCommentsAction());
        if (note.codNota) dispatch(getNoteCommentsAction(note.codNota));
    }, [dispatch, note.codNota])

    return <div style={{ paddingBottom: 20 }}>
        <div style={{ padding: comments.length ? 20 : "20px 20px 0px 20px" }}>
            <div className="p8">
                <Typography className="text-16 ml-auto mr-4 whiteText" style={{ fontWeight: 500 }}>
                    {getLocalizedString("comments")}
                </Typography>
                <CommentHistory societyPeople={societyPeople} note={note} comments={comments} />
            </div>
        </div>
        {
            comments.length > 0 && <div style={{ border: "1px solid gray", borderWidth: "0px 0px 1px 0px" }} />
        }
        <div style={{ padding: 20 }}>
            <div className="p8">
                <NewCommentBox codNota={note.codNota || null} />
            </div>
        </div>
    </div>
}

export default CommentsBox;
