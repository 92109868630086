import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { getLocalizedString } from "@icarius-localization/strings";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { NotificationsActiveIcon } from "@icarius-icons";

function NoteFormReminder(props) {
    const [isOpen, setIsOpen] = useState(false);
    let reminder = new Date();
    if (typeof reminder === "string") {
        reminder = createDateFromDDMMYYYY(props.reminder);
    }
    return (
        <Fragment>
            <Tooltip title={getLocalizedString("addReminder")} placement="bottom">
                <IconButton
                    className="w-32 h-32 mx-4 p-0"
                    onClick={() => setIsOpen(true)}
                >
                    <NotificationsActiveIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                </IconButton>
            </Tooltip>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                    className="hidden"
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    clearLabel={getLocalizedString("clear")}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    clearable
                    disablePast
                    value={reminder}
                    onChange={props.onChange}
                    DialogProps={{
                        className: "prevent-add-close"
                    }}
                />
            </MuiPickersUtilsProvider>
        </Fragment>
    );
}

export default NoteFormReminder;
