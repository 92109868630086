import React from 'react';
import { useMediaQuery, Grid } from '@material-ui/core';
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { getLocalizedString } from "@icarius-localization/strings";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DefaultImage from "@icarius-assets/images/no_profile_dark.png";
import { useSelector, useDispatch } from "react-redux";
import { getAppColor, getAvatar } from "src/app/selectors";
import { createNoteCommentAction } from "../../actions";
import { XS_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";

const onMediaFallback = event => event.target.src = DefaultImage;

const NewCommentBox = (props) => {

    const { codNota } = props;

    const dispatch = useDispatch();
    const avatar = useSelector(getAvatar);
    const color = useSelector(getAppColor);

    const useFullSizeCommentBox = useMediaQuery(`(max-width:${XS_DEVICE_WIDTH_BREAKPOINT}px)`); 

    const handleGenerate = values => {
        // Si tengo algo, entonces lo mando
        if (values.comment && values.comment !== "" && codNota) {
            dispatch(createNoteCommentAction(codNota, values.comment));
            window.setFormValueCommentBox("comment", "");
        }
    }

    let initialValues = { comment: "" }
    return <div>
        <Grid container>
            <Grid item xs={2} sm={1}>
            {
                avatar && avatar !== "-" && avatar !== "" ?
                <img src={IMAGES_ENDPOINT + avatar} alt="error" onError={onMediaFallback} className={"employee-image"} style={{ height: 50, width: 50 }} />
                : <div className={"employee-image"} style={{ background: color, height: 50, width: 50 }} />
            }
            </Grid>
            <Grid item xs={10} sm={11}>
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    onSubmit={handleGenerate}
                    initialValues={initialValues}
                    render={({ handleSubmit, form, submitting, pristine, values, active }) => {
                        window.setFormValueCommentBox = form.mutators.setValue;
                        return <form onSubmit={handleSubmit} noValidate>
                            <Grid container>
                                <Grid item xs={12} sm={10} style={{ paddingLeft: 20, paddingRight: useFullSizeCommentBox ? 0 : 20 }}>
                                    <TextField
                                        name="comment"
                                        margin={"none"}
                                        multiline={true}
                                        rows={2}
                                        placeholder={getLocalizedString("writeHereYourComment")}
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={2} justify="flex-end" style={{ paddingTop: 10 }}>
                                    <ButtonDialogAction
                                        type="submit"
                                        isAccept
                                        text={getLocalizedString("commentAction")}
                                    />                                    
                                </Grid>
                            </Grid>
                        </form>
                    }}
                />
            </Grid>
        </Grid>
    </div>
}

export default NewCommentBox;
