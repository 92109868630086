import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from '@material-ui/core';
import { ImageIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import GalleryDialog from "@icarius-common/galleryDialog/components/index";
import { getGalleryImages } from '@icarius-common/galleryDialog/selectors';

function NoteFormUploadImage(props) {

    const { handleSubmit, noteImg, uploadImage, deleteImage } = props;
    const [ galleryDialogIsOpen, setGalleryDialogIsOpen ] = useState(false);
    const [ imageIndex, setImageIndex ] = useState(null);
    const images = useSelector(getGalleryImages);

    useEffect(() => {
        const indexToSet = images.findIndex(image => image.img === noteImg);
        if(indexToSet > -1){
            setImageIndex(indexToSet);
        }
    }, [images, noteImg])

    const handleUploadImage = (file) => {
        setImageIndex(null);
        uploadImage(file);
    }

    const handleDeleteImage = (file) => {
        setImageIndex(null);
        deleteImage(file);
    }

    return (
        <>
            <label htmlFor="button-file">
                <Tooltip title={getLocalizedString("addImage")} placement="bottom">
                    <IconButton className="w-32 h-32 mx-4 p-0" component="span" onClick={() => setGalleryDialogIsOpen(true)}>
                        <ImageIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                    </IconButton>
                </Tooltip>
            </label>
            {
                galleryDialogIsOpen &&
                <GalleryDialog
                    open={galleryDialogIsOpen}
                    imagePreselected={imageIndex}                    
                    handleClose={() => setGalleryDialogIsOpen(false)}
                    handleSubmit={(img) => {
                        handleSubmit(img !== null ? images[img].img : "");
                        setImageIndex(img);
                    }}
                    handleUploadImage={handleUploadImage}
                    handleDeleteUserImage={handleDeleteImage}
                />
            }
        </>
    );
}

export default NoteFormUploadImage;
