import React, { useState, useCallback } from 'react';
import CheckListItem from "./CheckListItem";
import { formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString } from '@icarius-localization/strings';
import DeleteDialog from "./DeleteDialog";

const SubitemContainer = (props) => {

    const {
        handleListItemChange,
        handleListItemRemove,
        index,
        subitem,
        isOwner,
        isResponsible,
        parentIsDone,
        parentEndDate,
    } = props;

    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const handleChange = useCallback((event, percentage, responsible = null, subitems = null, endDate) => {

        //si el porcentaje nuevo es 100, checked es true, sino false
        //si el evento es de checkbox y es checked, porcentaje es 100, sino el de la prop

        let newEndDate;

        if (endDate) {
            const auxEndDate = formatDate(endDate);
            if (auxEndDate !== "Invalid date") {
                newEndDate = auxEndDate;
            }
        } else {
            newEndDate = subitem.endDate;
        }

        handleListItemChange(
            {
                index: index,
                label: event?.target.type === 'textarea' ? event.target.value.replace(/\n/g, '') : subitem.label,
                checked: Number.isInteger(percentage) ? percentage === 100 : event?.target.type === 'checkbox' ? event.target.checked : subitem.checked,
                percentage: Number.isInteger(percentage) ? percentage : event?.target.type === 'checkbox' ? 100 : subitem.percentage,
                date: subitem.date,
                endDate: newEndDate,
            });
    }, [index, subitem, handleListItemChange])

    return (
        <>
            <CheckListItem
                key={index}
                isSubitem
                item={subitem}
                isOwner={isOwner}
                isResponsible={isResponsible}
                parentIsDone={parentIsDone}
                endDateMinDate={createDateFromDDMMYYYY(subitem.date)}
                endDateMaxDate={parentEndDate}
                handleChange={handleChange}
                handleListItemRemove={() => setDeleteDialogIsOpen(true)}
            />
            {
                deleteDialogIsOpen &&
                <DeleteDialog
                    open={deleteDialogIsOpen}
                    text={getLocalizedString("deleteSubtaskConfirm")}
                    handleClose={() => setDeleteDialogIsOpen(false)}
                    handleConfirm={() => {
                        handleListItemRemove(subitem.label);
                        setDeleteDialogIsOpen(false);
                    }}
                />
            }
        </>
    )
}

export default SubitemContainer;
