import React from 'react';
import {
    Tooltip,
    Typography,
    IconButton,
    Fab,
    Grid,
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';
import NoteReminderLabel from '../label/noteReminderLabel';
import NoteLabel from '../label/noteLabel';
import CheckList from '../checklist/CheckList';
import NoteFormReminder from './NoteFormReminder';
import NoteFormUploadImage from './NoteFormUploadImage';
import NoteFormLabelMenu from './NoteFormLabelMenu';
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { getLocalizedString } from "@icarius-localization/strings";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import {
    DeleteIcon,
    PlaylistAddCheckIcon,
    UnarchiveIcon,
    ArchiveIcon,
    SendIcon,
    ShareIcon,
    AttachFileIcon,
    LabelIcon,
    PieChartIcon,
    FileCopyIcon
} from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import NoteAttachment from "../attachments/NoteAttachment";
import {
    uploadNoteAttachmentAction,
    deleteNoteAttachmentAction,
    shareNoteAction,
    sendNoteAction,
} from "../../actions";
import { connect } from "react-redux";
import { previewFromURL } from "@icarius-utils/download";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { uploadImageAction, deleteImageAction } from "@icarius-common/galleryDialog/actions";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getTheme } from "@icarius-pages/login/selectors";
import { createStructuredSelector } from "reselect";
import NoteFormFeedbackIndicator from './NoteFormFeedbackIndicator';
import UserSelectionDialog from "./userSelection/dialog";

const styles = () => ({
    cssTextAreaInput: {
        fontSize: "1.3rem",
        fontWeight: "100",
    },

    cssTitleInput: {
        fontSize: "1.5rem",
        fontWeight: "400",
    },
});

class NoteForm extends React.Component {
    constructor(props) {
        super(props);
        this.checkboxData = [];
        this.state = {
            isSendNoteOpen: false,
            attachments: [],
            userSelectionDialogIsOpen: false,
            checkListShareNote: [],
            checkListSendNote: [],
        }
    }

    componentDidMount() {
        const {
            note,
            groups,
            societyPeople,
            userCode,
        } = this.props;

        let filteredGroups = groups?.filter(g => g.isMemberOfGroup === true) || [];

        if (note.checklist?.length > 0) {
            // Armo el checkboxData para el Checkboxes
            note.checklist.forEach((item) => {
                let newSubChecklist = (item?.subitems || []).map((subitem) => {
                    return (
                        {
                            label: subitem.check,
                            checked: subitem.done === "Y",
                            responsible: subitem.responsible,
                            percentage: subitem.percentage,
                            date: subitem.date,
                            endDate: subitem.endDate,
                        })
                });

                return this.checkboxData.push(
                    {
                        label: item.check,
                        checked: item.done === "Y",
                        percentage: item.percentage,
                        responsible: item.responsible,
                        date: item.date,
                        subitems: newSubChecklist,
                        endDate: item.endDate,
                    }
                )
            });
        }

        const noteParticipants = note ? note.sharedWith.split(",") : [];

        let groupsCheckListSHARE = filteredGroups.map((group) => {
            return {
                key: group.id,
                name: group.name,
                img: group.img,
                isChecked: note ? noteParticipants.some(participant => participant === group.id) : false,
            }
        })

        let newUsersCheckListSHARE = societyPeople.map((employee) => {
            return {
                ...employee,
                isChecked: note ? noteParticipants.some(participant => participant === employee.key) : userCode === employee.key,
            }
        })

        const checkListShare = groupsCheckListSHARE.concat(newUsersCheckListSHARE).filter((employee) => employee.key !== userCode);

        let groupsCheckListSEND = filteredGroups.map((group) => {
            return {
                key: group.id,
                name: group.name,
                img: group.img,
                isChecked: false,
            }
        })

        let newUsersCheckListSEND = societyPeople.map((employee) => {
            return {
                ...employee,
                isChecked: false
            }
        })

        const checkListSend = groupsCheckListSEND.concat(newUsersCheckListSEND).filter((employee) => employee.key !== userCode);

        this.setState({ checkListShareNote: checkListShare, checkListSendNote: checkListSend });
    }

    uploadImage = (file) => {
        this.props.dispatch(uploadImageAction(file, "notes"));
    }

    deleteImage = (filename) => {
        this.props.dispatch(deleteImageAction(filename, "notes"));
    }

    shareNote = () => {
        this.setState({ userSelectionDialogIsOpen: true, isSendNoteOpen: false })
    }

    sendNote = () => {
        this.setState({ userSelectionDialogIsOpen: true, isSendNoteOpen: true })
    }

    handleCloseUserSelectionDialog = (keysToDelete) => {
        if (keysToDelete && keysToDelete.length > 0) {
            if (this.state.isSendNoteOpen) {
                let newUsersCheckList = this.state.checkListSendNote.map((employee) => {
                    return {
                        ...employee,
                        isChecked: false
                    }
                });

                this.setState({ userSelectionDialogIsOpen: false, checkListSendNote: newUsersCheckList })
            } else {
                let newUsersCheckList = this.state.checkListShareNote.map((employee) => {
                    return {
                        ...employee,
                        isChecked: keysToDelete.some(key => key === employee.key) ? !employee.isChecked : employee.isChecked,
                    }
                });

                this.setState({ userSelectionDialogIsOpen: false, checkListShareNote: newUsersCheckList })
            }
        } else {
            this.setState({ userSelectionDialogIsOpen: false })
        }
    }

    handleSetUsers = (values) => {
        const { isSendNoteOpen } = this.state;
        const { note } = this.props;
        const { codNota } = note;
        const regexGrupos = /^G\d{8}/;
        const arr = values.split(",");
        const splittedValues = arr.filter(n => n);
        let groups = [];
        let colaborators = [];

        // Primero, tengo que dividir colaboradores de grupo
        for (let h = 0; h < splittedValues.length; h++) {
            if (regexGrupos.test(splittedValues[h])) {
                groups.push(splittedValues[h]);
            } else {
                colaborators.push(splittedValues[h]);
            }
        }

        if (splittedValues.length > 0) {
            if (isSendNoteOpen) {
                // Estoy enviando la nota
                this.props.dispatch(sendNoteAction(codNota, colaborators, groups));
            } else {
                // Verifico si los array son iguales
                const originalArr = note.sharedWith.split(",");
                // Me fijo si hay nuevos o borrador en el nuevo array
                const removedElements = originalArr.filter(e => !splittedValues.includes(e));
                const newElements = splittedValues.filter(e => !originalArr.includes(e));

                if (newElements.length === 0 && removedElements.length === 0) {
                    this.props.dispatch(openSnackbarAction({
                        msg: 'Está intentando compartir la nota con los usuarios que ya tienen la nota compartida',
                        severity: "error"
                    }));
                } else {
                    // Estoy compartiendo la nota
                    this.props.dispatch(shareNoteAction(codNota, colaborators, groups));
                }
            }
        } else {
            //Si quiero descompartir todo (tengo deseleccionados todos)
            if (!isSendNoteOpen && note?.sharedWith?.split(",").length > 0) {
                //si es Compartir, y antes tenia compartido pero ahora deseleccione todos
                this.props.dispatch(shareNoteAction(codNota, [], []));
            } else {
                //si es Enviar, y no tengo seleccionado a nadie para enviar
                this.props.dispatch(openSnackbarAction({ msg: 'Debe seleccionar a alguien para enviar la nota', severity: "error" }));
            }
        }
    }

    handleUserClick = (userKey) => {
        const { checkListShareNote, checkListSendNote, isSendNoteOpen } = this.state;
        if (isSendNoteOpen === true) {
            let newCheckList = JSON.parse(JSON.stringify([...checkListSendNote]));
            let modifiedItem = checkListSendNote.find(user => user.key === userKey);
            const index = checkListSendNote.indexOf(modifiedItem);

            if (index > -1) {
                modifiedItem.isChecked = !modifiedItem.isChecked;
                newCheckList[index] = modifiedItem;
            }

            this.setState({ checkListSendNote: newCheckList });
        } else {
            let newCheckList = JSON.parse(JSON.stringify([...checkListShareNote]));
            let modifiedItem = checkListShareNote.find(user => user.key === userKey);
            const index = checkListShareNote.indexOf(modifiedItem);

            if (index > -1) {
                modifiedItem.isChecked = !modifiedItem.isChecked;
                newCheckList[index] = modifiedItem;
            }

            this.setState({ checkListShareNote: newCheckList });
        }
    }

    // Modifica la fecha de recordatorio
    handleDateChange = (date) => {
        window.setFormValue("reminder", date);
    }

    //Modifica los labels
    handleLabelsChange = (labels) => {
        window.setFormValue("labels", labels);
    }

    //Muestra u oculta la lista
    handleToggleList = (value) => {
        window.setFormValue("showList", !value);
    }

    // Al ocurrir un cambio en el checklist, guarda los cambios en el array
    handleChecklistChange = (checklist) => {
        checklist.sort((a, b) => moment(createDateFromDDMMYYYY(a.endDate)) - moment(createDateFromDDMMYYYY(b.endDate)))
        window.setFormValue("checklist", checklist);
    }

    // Archivar
    handleArchiveToggle = (value) => {
        window.setFormValue("archive", !value);
    }

    // Imagenes
    handleSetImage = (image) => {
        window.setFormValue("image", image);
    }

    handleRemoveImage = () => {
        window.setFormValue("image", "");
    }

    // Elimino el label
    handleRemoveLabel = (labels, id) => {
        const newLabels = labels.filter(_id => _id.labelCode !== id);
        window.setFormValue("labels", newLabels);
    }

    validateAndConfirm = (values) => {
        let data = {
            "titulo": this.getValue(values.title, "title"),
            "texto": this.getValue(values.description, "description"),
            "checklist": this.getValue(values.checklist, "checklist"),
            "labels": this.getValue(values.labels, "labels"),
            "img": this.getValue(values.image, "img"),
            "fecrev": this.getValue(values.reminder, "fecrev"),
            "archiv": this.getValue(values.archive, "archiv"),
        };

        // Parche para checklist
        if (data.checklist) {
            data.checklist = data.checklist.slice().reverse().filter((v, i, a) => a.findIndex(t => (t.check === v.check)) === i).reverse()
        }
        if (this.props.variant === "new") {
            data.attachments = values.attachments.filter(e => !(e.includes("/notes/attachments"))).join("|") || null;
            this.props.handleCreateNote(data);
        } else {
            data.codNota = this.props.note.codNota;
            data.attachments = values.attachments.filter(e => !(e.includes("/notes/attachments"))).join("|") || null;
            this.props.handleEditNote(data);
        }
    }

    getValue = (value, property) => {
        switch (property) {
            case "title":
                if (this.props.note.titulo === value) {
                    return null;
                } else {
                    if (value.length === 0) {
                        return "";
                    } else {
                        return value;
                    }
                }
            case "description":
                if (this.props.note.texto === value) {
                    return null;
                } else {
                    if (typeof value === "undefined" || value.length === 0) {
                        return "";
                    } else {
                        return value;
                    }
                }
            case "checklist": {
                let newChecklist = [];
                value.map(item => {
                    // Preparo los subitems tambien
                    let newSubChecklist = [];

                    if (item.subitems && item.subitems.length > 0) {
                        item.subitems.map(subitem => {
                            let newSubitemEndDate = "";
                            if (moment(subitem.endDate, "DD/MM/YYYY").format("YYYY/MM/DD") !== "Invalid date") {
                                newSubitemEndDate = moment(subitem.endDate, "DD/MM/YYYY").format("YYYY/MM/DD")
                            }
                            return newSubChecklist.push(
                                {
                                    check: subitem.label,
                                    done: subitem.checked === true ? "Y" : "N",
                                    responsible: subitem.responsible,
                                    percentage: subitem.percentage,
                                    endDate: newSubitemEndDate,
                                })
                        })
                    }

                    let newEndDate = "";
                    if (moment(item.endDate, "DD/MM/YYYY").format("YYYY/MM/DD") !== "Invalid date") {
                        newEndDate = moment(item.endDate, "DD/MM/YYYY").format("YYYY/MM/DD")
                    }

                    return newChecklist.push(
                        {
                            check: item.label,
                            done: item.checked === true ? "Y" : "N",
                            responsible: item.responsible,
                            percentage: item.percentage,
                            subitems: newSubChecklist,
                            endDate: newEndDate,
                        })
                })

                if (JSON.stringify(this.props.note.checklist) === JSON.stringify(newChecklist)) {
                    return null;
                } else {
                    if (newChecklist.length === 0) {
                        return "";
                    } else {
                        return newChecklist;
                    }
                }
            }
            case "labels": {
                let labelsFinal = [];

                if (value.length > 0) {
                    value.map(item => labelsFinal.push(item.labelCode))
                }

                if (labelsFinal.length === 0) {
                    return "";
                } else {
                    return labelsFinal;
                }
            }
            case "img": {
                return value || "";
            }
            case "fecrev": {
                if (this.props.note["Fecha de revision"] === value) {
                    return null
                } else {
                    if (moment(value).format("YYYY/MM/DD") !== "Invalid date") {
                        return moment(value).format("YYYY/MM/DD")
                    } else {
                        return ""
                    }
                }
            }
            case "archiv": {
                const booleanValue = value ? "Y" : "N";
                if (this.props.note.archiv === booleanValue) {
                    return null;
                } else {
                    return booleanValue;
                }
            };
            default: break;
        }
    }

    checkVariableIsNotFilled = (value) => {
        return typeof value === "object" || typeof value === "undefined" || value === "" || (value && value.length === 0)
    }

    attachFileToNote = (attachments, file) => {
        this.props.dispatch(uploadNoteAttachmentAction(file)).then(resp => {
            if (resp?.data?.status === "OK") {
                let newValues = JSON.parse(JSON.stringify(attachments));
                newValues.push(resp.data.filename);
                window.setFormValue("attachments", newValues)
            }
        });
    }

    handleRemoveAttachment = (attachments, file) => {
        this.props.dispatch(deleteNoteAttachmentAction(file)).then(resp => {
            if (resp?.data?.status === "OK") {
                let newValues = JSON.parse(JSON.stringify(attachments));
                newValues = newValues.filter(e => e !== file);
                window.setFormValue("attachments", newValues)
            }
        });
    }

    downloadAttachment = (attachment) => {
        previewFromURL(attachment);
    }

    render() {
        const {
            note,
            variant,
            onRemove,
            labels,
            classes,
            color,
            societyPeople,
            handleGrid,
            handleDuplicateNote,
            theme,
        } = this.props;

        const {
            userSelectionDialogIsOpen,
            checkListShareNote,
            checkListSendNote,
            isSendNoteOpen,
        } = this.state;

        let ownerName = "";

        const CustomFab = withStyles({
            root: {
                color: theme.theme === "dark" ? "white" : "black",
                backgroundColor: theme.theme === "dark" && "#222222",
                "&:hover": {
                    backgroundColor: theme.theme === "dark" ? "#2e2e2e" : "#cbcbcb",
                },
            },
        })(Fab);

        let initialValues;

        if (variant === "new") {
            initialValues = {
                title: "",
                description: "",
                checklist: [],
                archive: false,
                time: "",
                reminder: null,
                id: "",
                labels: [],
                image: "",
                showList: false,
                attachments: [],
            };
        } else {
            ownerName = societyPeople.find(e => e.key === note.creator)?.name;
            initialValues = {
                title: note.titulo,
                description: note.texto,
                checklist: this.checkboxData,
                archive: note.archiv === "Y",
                time: note["Fecha de actualizacion"] + " " + note["Hora de actualizacion"],
                reminder: note["Fecha de revision"],
                id: note.codNota,
                labels: note.labels,
                image: note.img,
                showList: false,
                attachments: note.attachments,
            };
        }

        const lastEditor = societyPeople.find(e => e.key === note.lastEditor);

        return (
            <>
                <Form
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                    }}
                    initialValues={initialValues}
                    onSubmit={this.validateAndConfirm}
                    render={({ handleSubmit, form, submitting, pristine, values, active }) => {
                        window.setFormValue = form.mutators.setValue;
                        let shouldNewFormButtonBeDisabled = this.checkVariableIsNotFilled(values.title);
                        return (
                            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                <div className="flex flex-col w-full">
                                    <div className="w-full">
                                        {
                                            values.image && values.image !== "" &&
                                            <div className="relative">
                                                <img
                                                    src={
                                                        values.image.substr(0, 4).includes("data")
                                                            ? values.image
                                                            : IMAGES_ENDPOINT + values.image + "?" + Math.floor(Math.random() * 101)
                                                    }
                                                    style={
                                                        values.image.substr(0, 4).includes("data")
                                                            ? { maxHeight: 200, height: "auto", width: "auto", maxWidth: "100%", borderRadius: 5 }
                                                            : { maxWidth: "100%", maxHeight: 200, height: "auto", width: "auto", borderRadius: 5, cursor: "pointer" }
                                                    }
                                                    className="w-full block"
                                                    onClick={() =>
                                                        values.image.substr(0, 4).includes("data")
                                                            ? undefined
                                                            : window.open(IMAGES_ENDPOINT + values.image, '_blank')
                                                    }
                                                    alt="note"
                                                />
                                                <CustomFab
                                                    className="absolute right-0 bottom-0 m-8"
                                                    variant="extended"
                                                    size="small"
                                                    aria-label="Delete Image"
                                                    onClick={this.handleRemoveImage}
                                                    style={{
                                                        bottom: 5,
                                                        right: 5
                                                    }}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                </CustomFab>
                                            </div>
                                        }
                                        {
                                            variant !== "new" && note.shared === "Y" && ownerName &&
                                            <div className="p-8">
                                                <Grid container alignItems="center" >
                                                    <LabelIcon htmlColor={color} fontSize="large" />
                                                    <Typography className="text-12 ml-auto mr-4 whiteText" style={{ fontWeight: 100 }}>
                                                        {ownerName}
                                                    </Typography>
                                                </Grid>
                                            </div>
                                        }
                                        <div className="p-8">
                                            <TextField
                                                name={"title"}
                                                margin={"none"}
                                                onInput={e => {
                                                    e.target.value = e.target.value.toString().slice(0, 100);
                                                }}
                                                placeholder={getLocalizedString("title")}
                                                className="font-bold"
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssTitleInput,
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className="p-8">
                                            <TextField
                                                name="description"
                                                margin={"none"}
                                                multiline={true}
                                                rows={4}
                                                placeholder={getLocalizedString("writeHereYourNote")}
                                                rowsMax={4}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssTextAreaInput,
                                                    },
                                                }}
                                            />
                                        </div>
                                        {
                                            ((values.checklist && values.checklist.length > 0) || values.showList) &&
                                            <CheckList
                                                owner={note.owner}
                                                isShared={note.sharedWith !== ""}
                                                name="checklist"
                                                checklist={values.checklist}
                                                onCheckListChange={this.handleChecklistChange}
                                                code={note.codNota}
                                                sharedWith={note.sharedWith}
                                            />
                                        }
                                        {
                                            (values.labels.length > 0 || values.reminder) &&
                                            <div className="flex flex-wrap w-full p-8 pb-0">
                                                {
                                                    values.reminder &&
                                                    <NoteReminderLabel className="mt-4 mr-4" date={values.reminder} />
                                                }
                                                {
                                                    values.labels && values.labels.map((item, i) => {
                                                        return (
                                                            <NoteLabel
                                                                actualLabels={values.labels}
                                                                labels={labels}
                                                                labelCode={item.labelCode}
                                                                key={i}
                                                                className="mt-4 mr-4"
                                                                onDelete={this.handleRemoveLabel}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        {
                                            (values.attachments && values.attachments.length > 0) &&
                                            <div className="flex flex-wrap w-full p-8 pb-0">
                                                <Typography className="text-12 ml-auto mt-8 mr-4 whiteText" style={{ fontWeight: 100 }}>
                                                    {getLocalizedString("attachments") + ":"}
                                                </Typography>
                                                <div>
                                                    {
                                                        values.attachments && values.attachments.map((item, i) => {
                                                            return (
                                                                <NoteAttachment
                                                                    key={i}
                                                                    className="mt-4 mr-4"
                                                                    attachments={values.attachments}
                                                                    attachment={item}
                                                                    onClick={this.downloadAttachment}
                                                                    onDelete={this.handleRemoveAttachment}
                                                                />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            values.time && values.time !== "undefined undefined" &&
                                            <div className="flex flex-wrap w-full p-8 pt-0">
                                                <Typography className="text-12 ml-auto mt-8 mr-4 whiteText" style={{ fontWeight: 100 }}>
                                                    {getLocalizedString("edited") + ": " + values.time}
                                                </Typography>
                                                {
                                                    note.shared === "Y" && lastEditor?.name &&
                                                    <Typography className="text-12 ml-auto mt-8 mr-4 ml-8 whiteText" style={{ fontWeight: 500 }}>
                                                        {lastEditor.name}
                                                    </Typography>
                                                }
                                            </div>
                                        }
                                        <NoteFormFeedbackIndicator
                                            value={note.feedback}
                                        />
                                    </div>
                                    <Grid container item xs={12}>
                                        <Grid container item sm={8} xs={12}>
                                            <NoteFormReminder reminder={values.reminder} onChange={this.handleDateChange} />
                                            <NoteFormUploadImage
                                                handleSubmit={this.handleSetImage}
                                                noteImg={note.img}
                                                uploadImage={this.uploadImage}
                                                deleteImage={this.deleteImage}
                                            />
                                            {
                                                values.checklist.length === 0 &&
                                                <Tooltip title={getLocalizedString("addChecklist")} placement="bottom">
                                                    <IconButton
                                                        className="w-32 h-32 mx-4 p-0"
                                                        style={{ fill: "var(--icons)" }}
                                                        onClick={() => this.handleToggleList(values.showList)}
                                                    >
                                                        <PlaylistAddCheckIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            <NoteFormLabelMenu labels={labels} values={values.labels} onChange={this.handleLabelsChange} />
                                            <Tooltip title={values.archive ? getLocalizedString("unarchive") : getLocalizedString("archive")} placement="bottom">
                                                <span>
                                                    <IconButton
                                                        className="w-32 h-32 mx-4 p-0"
                                                        onClick={() => this.handleArchiveToggle(values.archive)}
                                                        disabled={shouldNewFormButtonBeDisabled}
                                                    >
                                                        {
                                                            values.archive
                                                                ? <UnarchiveIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                                : <ArchiveIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                        }
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <input
                                                type="file"
                                                name={"file"}
                                                id="fileInputUpload"
                                                style={{ display: "none" }}
                                                onChange={e => this.attachFileToNote(values.attachments, e.target.files[0])}
                                            />
                                            <Tooltip title={getLocalizedString("attachFileToNote")} placement="bottom" >
                                                <label htmlFor="fileInputUpload">
                                                    <IconButton component={"span"} className="w-32 h-32 mx-4 p-0">
                                                        <AttachFileIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                    </IconButton>
                                                </label>
                                            </Tooltip>
                                            {
                                                this.props.userCode === note.owner && !note.feedback &&
                                                <Tooltip title={getLocalizedString("sendNote")} placement="bottom">
                                                    <IconButton
                                                        className="w-32 h-32 mx-4 p-0"
                                                        onClick={() => this.sendNote(note)}
                                                        disabled={shouldNewFormButtonBeDisabled}
                                                    >
                                                        <SendIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                variant !== "new" && this.props.userCode === note.owner && !note.feedback &&
                                                <Tooltip title={getLocalizedString("shareNote")} placement="bottom">
                                                    <IconButton
                                                        className="w-32 h-32 mx-4 p-0"
                                                        onClick={() => this.shareNote(note)}
                                                        disabled={shouldNewFormButtonBeDisabled}
                                                    >
                                                        <ShareIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                !shouldNewFormButtonBeDisabled && note.checklist.length > 0 &&
                                                <Tooltip title={getLocalizedString("noteTaskGridData")} placement="bottom">
                                                    <IconButton
                                                        className="w-32 h-32 mx-4 p-0"
                                                        onClick={handleGrid}
                                                        disabled={shouldNewFormButtonBeDisabled}
                                                    >
                                                        <PieChartIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                this.props.userCode === note.owner && !note.feedback &&
                                                <Tooltip title={getLocalizedString("duplicate")} placement="bottom">
                                                    <IconButton
                                                        className="w-32 h-32 mx-4 p-0"
                                                        onClick={() => handleDuplicateNote(note)}
                                                        disabled={shouldNewFormButtonBeDisabled}
                                                    >
                                                        <FileCopyIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            }                                           
                                        </Grid>
                                        <Grid container item sm={4} xs={12} justify="flex-end" alignItems="center">
                                            {
                                                variant === "new"
                                                    ? (
                                                        <ButtonDialogAction
                                                            type="submit"
                                                            isAccept
                                                            text={getLocalizedString("create")}
                                                            disabled={shouldNewFormButtonBeDisabled}
                                                        />
                                                    )
                                                    : (
                                                        <>
                                                            {
                                                                this.props.userCode === note.owner &&
                                                                <Tooltip title={getLocalizedString("deleteNote")} placement="bottom">
                                                                    <IconButton
                                                                        className="w-32 h-32 mx-4 p-0"
                                                                        onClick={() => onRemove({ codNota: note.codNota })}
                                                                    >
                                                                        <DeleteIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            }
                                                            <ButtonDialogAction
                                                                type="submit"
                                                                isAccept
                                                                text={getLocalizedString("save")}
                                                                disabled={shouldNewFormButtonBeDisabled !== false}
                                                            />
                                                        </>
                                                    )
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )
                    }}
                />
                {
                    userSelectionDialogIsOpen &&
                    <UserSelectionDialog
                        open={userSelectionDialogIsOpen}
                        users={isSendNoteOpen === true ? checkListSendNote : checkListShareNote}
                        handleClose={this.handleCloseUserSelectionDialog}
                        handleUserClick={this.handleUserClick}
                        handleSubmit={this.handleSetUsers}
                        title={getLocalizedString(isSendNoteOpen ? "sendNote" : "shareNote")}
                        buttonText={getLocalizedString(isSendNoteOpen ? "noteSendAction" : "noteShareAction")}
                    />
                }
            </>
        )
    }
}

export default connect(
    createStructuredSelector({
        theme: getTheme,
    })
)(withStyles(styles)(NoteForm));