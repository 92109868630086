function setDescriptionStyle(sentence, refEl, enabled, isTitle) {

    const fontSizes = {
        fontSize: isTitle ? 24 : 18,
        lineHeight: isTitle ? 28 : 22,
    };

    if (!refEl) {
        return;
    }

    refEl.style.fontSize = null;
    refEl.style.lineHeight = null;
    const refElWidth = refEl.clientWidth;

    if (refElWidth === 0) {
        return;
    }

    if (!enabled) {
        return;
    }

    const tmp = document.createElement('div');
    tmp.style.display = 'inline-block';
    tmp.style.fontSize = '10px';
    tmp.style.lineHeight = '10px';
    tmp.style.position = 'absolute';
    tmp.style.whiteSpace = 'pre-wrap';
    tmp.style.wordWrap = 'break-word';
    tmp.style.fontWeight = "200";
    tmp.style.pointerEvents = 'none';
    tmp.style.visibility = 'hidden';
    document.body.appendChild(tmp);

    let { lineHeight, fontSize } = fontSizes;

    tmp.textContent = sentence;
    tmp.style.width = refElWidth + "px";
    tmp.style.fontSize = fontSize + "px";
    tmp.style.lineHeight = lineHeight + "px";


    document.body.removeChild(tmp);

    lineHeight = fontSizes.lineHeight;
    fontSize = fontSizes.fontSize;
    refEl.style.fontSize = fontSize + "px";
    refEl.style.lineHeight = lineHeight + "px";
    refEl.style.fontWeight = isTitle ? "500" : "200";
    refEl.style.overflow = "hidden";
    refEl.style.wordBreak = "break-word";
    refEl.style.display= "-webkit-box";
    refEl.style.WebkitLineClamp= isTitle ? "1" : "4";
    refEl.style.webkitBoxOrient="vertical";
}

export default setDescriptionStyle;
