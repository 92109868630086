import React from 'react';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';

const NoteLabel = (props) =>  {

    const { labels, className, onDelete, actualLabels } = props;

    if (!labels) {
        return null;
    }

    const label = labels.find(label => label.labelCode === props.labelCode);

    if (!label) {
        return null;
    }
    
    return (
        <Chip
            label={label.nombre}
            classes={{
                root: clsx("h-24 br-6 label-width", className),
                outlined: "whiteText-border",
                label: "px-6 py-4",
                deleteIcon: "w-16 ml-0 whiteText",
            }}
            className="whiteText"
            variant="outlined"
            style={{borderColor: "white !important"}}
            onDelete={onDelete ? () => onDelete(actualLabels, label.labelCode) : undefined}
        />
    );
}

export default NoteLabel;