import React, { useMemo, useState } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { getLocalizedString } from "@icarius-localization/strings";
import GlobalProgress from "./myToDo/globalProgress";
import PendingTasks from "./myToDo/pendingTasks";
import {
    Divider,
    Select,
    MenuItem,
    Typography,
    Grid,
} from "@material-ui/core";
import UserSelectionColumn from "./myToDo/column";
import CommonPage from "@icarius-common/commonPage";

const MODES = ['Todos los pendientes', 'Todas las tareas'];

const MyToDo = (props) => {

    const { notes, openNoteDialog, societyPeople, color, handleGoBack, gridTheme } = props;

    const [userCode, setUserCode] = useState(null);
    const [mode, setMode] = useState(MODES[0]);

    const nonArchivedNotes = useMemo(() => {
        return notes.filter(note => note.archiv === "N");
    }, [notes])

    let filteredSocietyPeople = useMemo(() => {
        let collaborators = [];
        nonArchivedNotes.forEach((note) => {
            note.checklist.forEach((task) => {
                if (mode === MODES[0]) {
                    if (task.percentage !== 100) {
                        collaborators.push(task.responsible)
                    }
                } else {
                    collaborators.push(task.responsible)
                }
            })
        });
        collaborators = [...new Set(collaborators)];

        return societyPeople.filter(person => collaborators.find(e => e === person.key));
    }, [nonArchivedNotes, societyPeople, mode])

    const numberOfTasks = nonArchivedNotes.reduce(function (a, b) {
        return a + b.checklist.length;
    }, 0);

    const goBackButton = () => (
        <CustomIconButton
            title={getLocalizedString("goBack")}
            onClick={handleGoBack}
            type={"goBack"}
        />
    )

    if (nonArchivedNotes.length > 0 && numberOfTasks > 0) {
        return (
            <CommonPage
                isNotGridPage
                menuItems={[goBackButton]}
            >
                <Grid container justify="space-between" alignItems="center" item xs={12} style={{ padding: '0px 50px' }}>
                    <Grid item md={8} xs={12}>
                        <GlobalProgress
                            notes={nonArchivedNotes}
                            gridTheme={gridTheme}
                            userCode={userCode}
                            societyPeople={filteredSocietyPeople}
                            showAllTasks={mode === "Todas las tareas"}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Select
                            labelId="branchLabel"
                            fullWidth
                            onChange={(e) => setMode(e.target.value)}
                            margin={"none"}
                            value={mode}
                        >
                            <MenuItem className={"whiteText"} value={MODES[0]}>
                                {MODES[0]}
                            </MenuItem>
                            <MenuItem className={"whiteText"} value={MODES[1]}>
                                {MODES[1]}
                            </MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider style={{ margin: "15px 50px", background: color, height: 2 }} />
                </Grid>
                <Grid container item xs={12} justify={"space-between"} alignItems="flex-start" >
                    <Grid container item lg={6} xs={12} justify={"center"}>
                        <PendingTasks
                            notes={nonArchivedNotes}
                            openNoteDialog={openNoteDialog}
                            gridTheme={gridTheme}
                            color={color}
                            userCode={userCode}
                            showAllTasks={mode === "Todas las tareas"}
                        />
                    </Grid>
                    <Grid container item lg={6} xs={12} justify={"center"}>
                        <UserSelectionColumn
                            color={color}
                            gridTheme={gridTheme}
                            employees={filteredSocietyPeople}
                            handleUserClick={setUserCode}
                            loggedUserIsSelectable={false}
                            selectedEmployee={userCode}
                        />
                    </Grid>
                </Grid>
            </CommonPage>
        )
    }

    return (
        <CommonPage
            isNotGridPage
            menuItems={[goBackButton]}
        >
            <Grid container justify="center" alignItems="center" item xs={12} style={{ padding: '0px 50px', textAlign: "center", height: "60vh" }}>
                <Grid item lg={3} xs={1} />
                <Grid item lg={6} xs={10}>
                    <Typography
                        className='whiteText'
                        style={{
                            fontSize: '2rem',
                            fontWeight: 700,
                            textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : ''
                        }}
                    >
                        {'No es posible visualizar su avance, porque usted no tiene tareas asignadas en este momento.'}
                    </Typography>
                </Grid>
                <Grid item lg={3} xs={1} />
            </Grid>
        </CommonPage>
    )
}

export default MyToDo;