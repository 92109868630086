import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import UserSection from "./userSection";
import { SearchIcon } from "@icarius-icons/";

const UserSelectionColumn = (props) => {

  const [userInput, setUserInput] = useState(""); //para el input en si, e ir actualizando letra a letra
  const [searchValue, setSearchValue] = useState(""); //el texto del input cuando el user deja de escribir, es lo que se le envia a UserSection para filtrar

  const { color, gridTheme, employees, handleUserClick, loggedUserIsSelectable, selectedEmployee } = props;

  const theme = useSelector(getTheme);
  const filterBarColor = theme.theme === "dark" ? "#747474" : "#949494";

  useEffect(() => {
    //para poner delay al seteado de searchValue y que no filtre letra por letra, sino cuando ya deje de escribir
    const timeOutId = setTimeout(() => setSearchValue(userInput), 500);
    return () => clearTimeout(timeOutId);
  }, [userInput]);

  return (
    <Grid container direction="row" item xs={12} style={{ padding: '0px 50px', marginBottom: 70 }}>
      <Grid item xs={12}>
        <Typography
          className='whiteText' style={{
            fontSize: 18,
            paddingBottom: 20,
            fontWeight: 700,
            textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : ''
          }}
        >
          {'Ver progreso de'}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ paddingBottom: 20 }}>
        <div style={{ display: "flex", alignItems: "center", height: 32, width: 340 }}>
          <TextField
            fullWidth
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={e => setUserInput(e.target.value)}
            style={{ borderBottom: `1px solid ${filterBarColor}` }}
            placeholder={getLocalizedString("groupSearchLabel")}
            type="text"
            value={userInput}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <UserSection
          searchValue={searchValue}
          color={color}
          employees={employees}
          handleUserClick={handleUserClick}
          selectedEmployee={selectedEmployee}
          loggedUserIsSelectable={loggedUserIsSelectable}
        />
      </Grid>
    </Grid>
  );
}

export default UserSelectionColumn;
