import NAME from "./constants";

export const GET_NOTES = NAME + "/GET_NOTES";
export const GET_NOTES_FULFILLED = NAME + "/GET_NOTES_FULFILLED";
export const GET_NOTES_REJECTED = NAME + "/GET_NOTES_REJECTED";
export const GET_LABELS = NAME + "/GET_NOTES";
export const GET_LABELS_REJECTED = NAME + "/GET_LABELS_REJECTED";
export const GET_LABELS_FULFILLED = NAME + "/GET_LABELS_FULFILLED";
export const CREATE_NOTE = NAME + "/CREATE_NOTE";
export const CREATE_NOTE_REJECTED = NAME + "/CREATE_NOTE_REJECTED";
export const CREATE_NOTE_FULFILLED = NAME + "/CREATE_NOTE_FULFILLED";
export const EDIT_NOTE = NAME + "/EDIT_NOTE";
export const EDIT_NOTE_REJECTED = NAME + "/EDIT_NOTE_REJECTED";
export const EDIT_NOTE_FULFILLED = NAME + "/EDIT_NOTE_FULFILLED";
export const DELETE_NOTE = NAME + "/DELETE_NOTE";
export const DELETE_NOTE_REJECTED = NAME + "/DELETE_NOTE_REJECTED";
export const DELETE_NOTE_FULFILLED = NAME + "/DELETE_NOTE_FULFILLED";
export const CREATE_LABEL = NAME + "/CREATE_LABEL";
export const CREATE_LABEL_REJECTED = NAME + "/CREATE_LABEL_REJECTED";
export const CREATE_LABEL_FULFILLED = NAME + "/CREATE_LABEL_FULFILLED";
export const EDIT_LABEL = NAME + "/EDIT_LABEL";
export const EDIT_LABEL_REJECTED = NAME + "/EDIT_LABEL_REJECTED";
export const EDIT_LABEL_FULFILLED = NAME + "/EDIT_LABEL_FULFILLED";
export const DELETE_LABEL = NAME + "/DELETE_LABEL";
export const DELETE_LABEL_REJECTED = NAME + "/DELETE_LABEL_REJECTED";
export const DELETE_LABEL_FULFILLED = NAME + "/DELETE_LABEL_FULFILLED";
export const GET_COMMENTS_FOR_NOTE = NAME + "/GET_COMMENTS_FOR_NOTE";
export const GET_COMMENTS_FOR_NOTE_REJECTED = NAME + "/GET_COMMENTS_FOR_NOTE_REJECTED";
export const GET_COMMENTS_FOR_NOTE_FULFILLED = NAME + "/GET_COMMENTS_FOR_NOTE_FULFILLED";
export const GET_SOCIETY_AND_GROUPS = NAME + "/GET_SOCIETY_AND_GROUPS";
export const GET_SOCIETY_AND_GROUPS_REJECTED = NAME + "/GET_SOCIETY_AND_GROUPS_REJECTED";
export const GET_SOCIETY_AND_GROUPS_FULFILLED = NAME + "/GET_SOCIETY_AND_GROUPS_FULFILLED";
export const ADD_NEW_COMMENT = NAME + "/ADD_NEW_COMMENT";
export const ADD_NEW_COMMENT_FULFILLED = NAME + "/ADD_NEW_COMMENT_FULFILLED";
export const ADD_NEW_COMMENT_REJECTED = NAME + "/ADD_NEW_COMMENT_REJECTED";
export const DELETE_COMMENT = NAME + "/DELETE_COMMENT";
export const DELETE_COMMENT_REJECTED = NAME + "/DELETE_COMMENT_REJECTED";
export const DELETE_COMMENT_FULFILLED = NAME + "/DELETE_COMMENT_FULFILLED";
export const EDIT_COMMENT = NAME + "/EDIT_COMMENT";
export const EDIT_COMMENT_FULFILLED = NAME + "/EDIT_COMMENT_FULFILLED";
export const EDIT_COMMENT_REJECTED = NAME + "/EDIT_COMMENT_REJECTED";
export const UPLOAD_ATTACHMENT = NAME + "/UPLOAD_ATTACHMENT";
export const UPLOAD_ATTACHMENT_FULFILLED = NAME + "/UPLOAD_ATTACHMENT_FULFILLED";
export const UPLOAD_ATTACHMENT_REJECTED = NAME + "/UPLOAD_ATTACHMENT_REJECTED";
export const DELETE_ATTACHMENT = NAME + "/DELETE_ATTACHMENT";
export const DELETE_ATTACHMENT_REJECTED = NAME + "/DELETE_ATTACHMENT_REJECTED";
export const DELETE_ATTACHMENT_FULFILLED = NAME + "/DELETE_ATTACHMENT_FULFILLED";
export const CLEAR_COMMENTS = NAME + "/CLEAR_COMMENTS";
export const SEND_NOTE = NAME + "/SEND_NOTE";
export const SEND_NOTE_FULFILLED = NAME + "/SEND_NOTE_FULFILLED";
export const SEND_NOTE_REJECTED = NAME + "/SEND_NOTE_REJECTED";
export const SHARE_NOTE = NAME + "/SHARE_NOTE";
export const SHARE_NOTE_FULFILLED = NAME + "/SHARE_NOTE_FULFILLED";
export const SHARE_NOTE_REJECTED = NAME + "/SHARE_NOTE_REJECTED";
export const ACCEPT_NOTE = NAME + "/ACCEPT_NOTE";
export const ACCEPT_NOTE_FULFILLED = NAME + "/ACCEPT_NOTE_FULFILLED";
export const ACCEPT_NOTE_REJECTED = NAME + "/ACCEPT_NOTE_REJECTED";
export const REFUSE_NOTE = NAME + "/REFUSE_NOTE";
export const REFUSE_NOTE_FULFILLED = NAME + "/REFUSE_NOTE_FULFILLED";
export const REFUSE_NOTE_REJECTED = NAME + "/REFUSE_NOTE_REJECTED";
export const SEND_TASK_REMINDER = NAME + "/SEND_TASK_REMINDER";
export const SEND_TASK_REMINDER_FULFILLED = NAME + "/SEND_TASK_REMINDER_FULFILLED";
export const SEND_TASK_REMINDER_REJECTED = NAME + "/SEND_TASK_REMINDER_REJECTED";