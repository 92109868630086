import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { sendNoteTaskReminderAction } from "@icarius-pages/notes/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useNotesProgress = (notes, societyPeople, userData) => {

    const dispatch = useDispatch();

    const [gridDialogIsOpen, setGridDialogIsOpen] = useState(false);

    const getStatusText = (progress, endDate) => {
        if (progress === 100) return getLocalizedString("taskFinished");

        const dateSubtraction = createDateFromDDMMYYYY(endDate).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0);
        const days = Math.round(Math.abs((dateSubtraction) / (24 * 60 * 60 * 1000)));

        if (dateSubtraction === 0) return getLocalizedString("taskEndsToday");
        if (dateSubtraction > 0) return `${getLocalizedString("taskDaysRemaining")}: ${days}`;
        if (dateSubtraction < 0) return `${getLocalizedString("taskDelay")}: ${days}`;
    }

    const handleSendMail = (agGrid) => {
        let selectedRows = agGrid.current.api.getSelectedRows();

        //Si no hay seleccionado, indico que no hay mail para envilar
        if (selectedRows.length === 0) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
            return;
        }

        //validar que selectedRows no tenga filas de notas NO propias
        if (selectedRows.some((row) => row.ownerCode !== userData?.code)) {
            dispatch(openSnackbarAction({ msg: getLocalizedString("noteMailOwner"), severity: "warning", duration: 7000 }));
            return;
        }

        const dataToSend = selectedRows.map(doc => (
            {
                responsible: doc.employeeCode,
                noteName: doc.note,
                taskName: doc.task,
                percentage: parseFloat(doc.taskProgress) * 100,
                subtaskName: doc.subtask,
                subtaskPercentage: parseFloat(doc.subtaskProgress) * 100,
                updateDate: doc.date,
                status: doc.status,
                endDate: doc.endDate,
            }
        ));

        dispatch(sendNoteTaskReminderAction(dataToSend));
    };

    const gridData = useMemo(() => {
        if (notes.length < 1 || societyPeople.length < 1) return [];

        let finalData = [];

        for (let indexNotes = 0; indexNotes < notes.length; indexNotes++) { //por cada nota
            const tasks = notes[indexNotes]?.checklist;
            if (tasks?.length > 0) { //si tiene tareas    
                let globalProgress = notes[indexNotes].checklist.reduce((sum, value) => sum + value.percentage, 0) / notes[indexNotes].checklist.length;

                for (let indexTasks = 0; indexTasks < tasks.length; indexTasks++) { //por cada tarea
                    const subtasks = tasks[indexTasks]?.subitems;
                    const userName = tasks[indexTasks].responsible !== "" ? societyPeople.find(person => person.key === tasks[indexTasks].responsible)?.name : "";

                    if (subtasks?.length > 0) { //si la tarea TIENE subtareas

                        for (let indexSubtasks = 0; indexSubtasks < subtasks.length; indexSubtasks++) { //por cada subtarea

                            finalData.push({
                                employeeCode: tasks[indexTasks].responsible,
                                name: userName,
                                task: tasks[indexTasks].check,
                                taskProgress: `${tasks[indexTasks].percentage / 100}`,
                                note: notes[indexNotes].titulo,
                                subtask: subtasks[indexSubtasks].check,
                                subtaskProgress: `${subtasks[indexSubtasks].percentage / 100}`,
                                date: subtasks[indexSubtasks].date,
                                endDate: subtasks[indexSubtasks].endDate,
                                status: getStatusText(subtasks[indexSubtasks].percentage, subtasks[indexSubtasks].endDate),
                                globalProgress: `${globalProgress / 100}`,
                                owner: notes[indexNotes].ownerName,
                                ownerCode: notes[indexNotes].owner,
                                codNota: notes[indexNotes].codNota,
                            });
                        }
                    } else { //si la tarea NO tiene subtareas
                        finalData.push({
                            employeeCode: tasks[indexTasks].responsible,
                            name: userName,
                            task: tasks[indexTasks].check,
                            taskProgress: `${tasks[indexTasks].percentage / 100}`,
                            note: notes[indexNotes].titulo,
                            subtask: "",
                            subtaskProgress: "",
                            date: tasks[indexTasks].date,
                            endDate: tasks[indexTasks].endDate,
                            status: getStatusText(tasks[indexTasks].percentage, tasks[indexTasks].endDate),
                            globalProgress: `${globalProgress / 100}`,
                            owner: notes[indexNotes].ownerName,
                            ownerCode: notes[indexNotes].owner,
                            codNota: notes[indexNotes].codNota,
                        });
                    }
                }
            }
        }
        return finalData;
    }, [notes, societyPeople])

    const notesProgressData = {
        gridData,
        gridDialogIsOpen,
    };

    const notesProgressFunctions = {
        setGridDialogIsOpen,
        handleSendMail,
    };

    return {
        notesProgressData,
        notesProgressFunctions,
    }
}

export default useNotesProgress;
