import React, { useState } from 'react';
import { Popover, ClickAwayListener, IconButton, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { LabelIcon, CheckBoxIcon, CheckBoxOutlineBlankIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";

function NoteFormLabelMenu(props) {
    const labels = props.labels;

    const [anchorEl, setAnchorEl] = useState(null);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleToggleLabel(label) {
        let newLabelList = props.values;
        if (props.values.find(item => item.labelCode === label.labelCode)) {
            newLabelList = props.values.filter(item => item.labelCode !== label.labelCode)
        } else {
            newLabelList.push(label)
        }

        props.onChange(newLabelList);
    }

    const valueLabels = props.values.map(label => label.labelCode);
    return (
        <div>
            <Tooltip title={getLocalizedString("changeLabels")} placement="bottom">

                <IconButton className="w-32 h-32 mx-4 p-0" onClick={handleMenuClick}>
                    <LabelIcon fontSize="small" style={{ fill: "var(--icons)" }} />
                </IconButton>
            </Tooltip>
            <Popover
                hideBackdrop={true}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                className="pointer-events-none"
                classes={{
                    paper: "pointer-events-auto py-8 prevent-add-close dialog-container"
                }}
            >
                <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleMenuClose}>
                    <List className="p-0">
                        {Object.entries(labels).map(([key, label]) => {
                            return <ListItem
                                key={label.labelCode}
                                button
                                dense
                                onClick={() => handleToggleLabel(label)}
                            >
                                {valueLabels.includes(label.labelCode) ? <CheckBoxIcon className="list-item-icon text-16 whiteText" color="action" /> : <CheckBoxOutlineBlankIcon className="list-item-icon text-16 whiteText" color="action" />}
                                <ListItemText className="truncate pl-8 whiteText" primary={label.nombre} disableTypography={true} />
                            </ListItem>
                        })}
                    </List>
                </ClickAwayListener>
            </Popover>
        </div>
    );
}

export default NoteFormLabelMenu;

