import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { Typography, DialogContent, DialogActions, DialogTitle, Dialog } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable"; 
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    minWidth: "30%",
  },
})(Dialog);

const DeleteDialog = (props) => {
  
  const { open, text, handleClose, handleConfirm } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("atention")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Typography className="whiteText" variant="h6" gutterBottom>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={handleClose}
          text={getLocalizedString("disagree")}
        />
        <ButtonDialogAction
          onClick={handleConfirm}
          isAccept
          text={getLocalizedString("agree")}
        />
      </DialogActions>
    </StyledDialog>
  );
}

export default DeleteDialog;
