import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { CloseIcon } from '@icarius-icons';
import { XS_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";
import { ArrowLeft } from "@icarius-icons";
import { getTheme } from "@icarius-pages/login/selectors";
import { getLocalizedString } from '@icarius-localization/strings';
import { getGalleryImagesAction } from '@icarius-common/galleryDialog/actions';
import DialogTransition from "@icarius-common/dialogTransition";
import NoteForm from './noteForm/NoteForm';
import TasksGridDialogContent from "./tasksGridDialogContent";
import CommentsBox from "./comments/CommentsBox";

const NoteDialog = (props) => {

    const {
        open,
        labels,
        handleOnChange,
        handleOnRemove,
        handleClose,
        selectedNote,
        handleCreateNote,
        handleEditNote,
        handleDuplicateNote,
        societyPeople,
        groups,
        color,
        userCode,
        societySelected,
        gridData,
        dateFormat,
        handleSendMail,
    } = props;

    const [commentBoxIsOpen, setCommentBoxIsOpen] = useState(false);
    const [gridIsOpen, setGridIsOpen] = useState(false);

    const filteredGridData = selectedNote?.codNota ? gridData.filter((row) => row.codNota === selectedNote.codNota) : gridData;

    const theme = useSelector(getTheme);
    const dispatch = useDispatch();

    const closeClick = () => {
        setCommentBoxIsOpen(false);
        handleClose();
    }

    const useFullSizeCommentBox = useMediaQuery(`(max-width:${XS_DEVICE_WIDTH_BREAKPOINT}px)`);

    useEffect(() => { //si se agranda la ventana arriba de XS_DEVICE_WIDTH_BREAKPOINT, poner el commentbox en modo no responsive
        !useFullSizeCommentBox && setCommentBoxIsOpen(false);
        dispatch(getGalleryImagesAction("notes"));
    }, [useFullSizeCommentBox, dispatch])

    if (!selectedNote) return null;

    return (
        <Dialog
            classes={{ paper: "w-full m-24" }}
            TransitionComponent={DialogTransition}
            open={open}
            maxWidth={"lg"}
            fullWidth={true}
        >
            <DialogTitle style={{ background: commentBoxIsOpen ? theme.theme === "dark" ? "black" : "#e1e1e1" : "" }}>
                {
                    commentBoxIsOpen &&
                    <div
                        onClick={() => setCommentBoxIsOpen(false)}
                        style={{ cursor: "pointer", paddingTop: 5, display: "flex", alignItems: "center" }}
                    >
                        <ArrowLeft className={"icon"} style={{ color: color }} />
                        <Typography
                            variant="subtitle1"
                            className="whiteText"
                            onClick={() => setCommentBoxIsOpen(true)}
                            style={{ marginLeft: 20, fontWeight: 700, fontSize: 14 }}
                        >
                            {getLocalizedString("comments")}
                        </Typography>
                    </div>
                }
                <CloseIcon className={"dialog-close-icon icon"} onClick={closeClick} />
            </DialogTitle>
            <div className={"dialog-container"} style={{ overflowY: "auto" }}>
                {/* si esta abierto el commentbox responsive, ocultar el otro pero no destruirlo */}
                <DialogContent style={{ display: commentBoxIsOpen ? "none" : "block" }}>
                    <NoteForm
                        handleCreateNote={handleCreateNote}
                        handleEditNote={handleEditNote}
                        note={selectedNote}
                        onChange={handleOnChange}
                        onClose={closeClick}
                        onRemove={handleOnRemove}
                        labels={labels}
                        variant={selectedNote.id === "" ? "new" : "edit"}
                        color={color}
                        societyPeople={societyPeople}
                        groups={groups}
                        userCode={userCode}
                        handleGrid={() => setGridIsOpen(prev => !prev)}
                        handleDuplicateNote={handleDuplicateNote}
                    />
                </DialogContent>
                {
                    gridIsOpen &&
                    <div style={{ margin: 10 }}>
                        <TasksGridDialogContent
                            handleSendMail={handleSendMail}
                            data={filteredGridData}
                            dateFormat={dateFormat}
                            societySelected={societySelected}
                        />
                    </div>
                }
                {
                    selectedNote.id !== "" &&
                    <div style={{ background: theme.theme === "dark" ? "black" : "#e1e1e1" }}>
                        <div className="w-full">
                            {
                                (!useFullSizeCommentBox || (useFullSizeCommentBox && commentBoxIsOpen))
                                    ? <CommentsBox societyPeople={societyPeople} note={selectedNote} />
                                    : (
                                        !commentBoxIsOpen &&
                                        <div style={{ paddingTop: 20, paddingBottom: 20, cursor: "pointer" }} onClick={() => setCommentBoxIsOpen(true)}>
                                            <Typography
                                                variant="subtitle1"
                                                className="whiteText"
                                                style={{ marginLeft: 20, textDecoration: "underline", fontWeight: 500, fontSize: 14 }}
                                            >
                                                {getLocalizedString("viewComments")}
                                            </Typography>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                }
            </div>
        </Dialog>
    )
}

export default NoteDialog;
