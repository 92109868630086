import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Checkbox, IconButton, useMediaQuery } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import ProfileImg from "@icarius-common/profileImg";
import { VariableSizeList as List } from 'react-window';
import { getUserData } from "../../../../../app/selectors";

const UserSection = React.memo((props) => {

  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const {
    searchValue,
    employees,
    isActualMembersColumn,
    handleUserClick,
    loggedUserIsSelectable,
  } = props;

  const matchesMoreOrEqualThan960 = useMediaQuery(`(min-width:960px)`);
  const matchesLessThan600 = useMediaQuery(`(max-width:600px)`);
  const userData = useSelector(getUserData);

  useEffect(() => {
    // para el input de la segunda columna, si es la primera columna, no filtra y queda una copia de employees
    let filteredEmployeesAux = [...employees];

    if (filteredEmployeesAux && filteredEmployeesAux.length > 0 && searchValue && searchValue !== "") {
      filteredEmployeesAux = filteredEmployeesAux.filter((employee) =>
        employee.name && employee.name.toLowerCase().includes(searchValue.toLowerCase())
      )
    }

    setFilteredEmployees(filteredEmployeesAux);
  }, [searchValue, employees])

  const Row = ({ index, style }) =>
    <div style={style}>
      <Grid container alignItems="center" key={filteredEmployees[index].key} style={{ height: 60, paddingBottom: 10 }}>
        <Grid item xs={2}>
          <ProfileImg
            image={Boolean(filteredEmployees[index]) ?
              filteredEmployees[index]["FOTOGRAFIA"] || filteredEmployees[index]["img"]
              : ""}
            size={matchesLessThan600 ? 30 : 40}
          />
        </Grid>
        <Grid
          item xs={8}
          style={{ cursor: (loggedUserIsSelectable || userData.code !== filteredEmployees[index].key) ? "pointer" : "" }}
          onClick={(loggedUserIsSelectable || userData.code !== filteredEmployees[index].key) ? (() => handleUserClick(filteredEmployees[index].key)) : null}
        >
          <Typography variant="h6" className="whiteText" style={{ fontWeight: 100, fontSize: matchesLessThan600 ? 14 : 16 }}>
            {filteredEmployees[index].name}
          </Typography>
        </Grid>
        <Grid container alignItems="center" justify="center" item xs={2}>
          {
            (loggedUserIsSelectable || userData.code !== filteredEmployees[index].key) ?
              (
                isActualMembersColumn ?
                  <IconButton onClick={() => handleUserClick(filteredEmployees[index].key)} color="inherit">
                    <CloseIcon style={{ fill: "var(--icons)" }} />
                  </IconButton>
                  :
                  <Checkbox onChange={() => handleUserClick(filteredEmployees[index].key)} checked={filteredEmployees[index].isChecked} />
              )
              :
              null
          }
        </Grid>
      </Grid>
    </div>

  return <List
    style={{ overflowX: "hidden" }}
    className="List"
    height={matchesLessThan600 ? 150 : 300}
    itemCount={filteredEmployees.length}
    itemSize={() => 60}
    width={matchesMoreOrEqualThan960 && 360}
  >
    {Row}
  </List>
})

export default UserSection;
