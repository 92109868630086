import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { getAppColor, getUserRole } from "src/app/selectors";
import {
    Grid,
    Tooltip,
    IconButton,
    Checkbox,
    ListItem,
    TextField,
    Slider,
    Typography,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { DeleteIcon, FormatListBulleted } from "@icarius-icons";
import ProfileImg from "@icarius-common/profileImg";
import { getLocalizedString } from '@icarius-localization/strings';
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { MedalTableIcon } from '@icarius-icons/index';
import { MedalTable } from '@icarius-common/medalTable';

const MIN_DATE_DEFAULT = new Date(1900, 1, 1);
const MAX_DATE_DEFAULT = new Date(2100, 1, 1);

const CheckListItem = (props) => {

    const {
        item,
        isSubitem,
        isShared,
        isOwner,
        isResponsible,
        parentIsDone,
        endDateMinDate,
        endDateMaxDate,
        responsibleName,
        profileImg,
        getResponsibleTooltipText,
        handleChange,
        handleListItemRemove,
        handleOpenResponsibleDialog,
        handleOpenSubitemDialog
    } = props;

    const [medalTableIsOpen, setMedalTableIsOpen] = useState(false);

    const color = useSelector(getAppColor);
    const level = useSelector(getUserRole);
    const hasSubitem = Boolean(item?.subitems?.length > 0);
    const endDateFormatted = item.endDate ? createDateFromDDMMYYYY(item.endDate) : null;

    const isDone = item.percentage === 100;
    const isSubtaskDisabled = (isSubitem && !isResponsible && !isOwner) || (isSubitem && parentIsDone);
    const isMedalTableDisabled = !(isOwner && !isResponsible && level !== 'C');

    return (
        <>
            <ListItem dense style={{ padding: "24px 16px " }}>
                <Grid container justify="space-between" direction="row">
                    {/* Checkbox */}
                    <Grid container item xs={1} justify="flex-start" alignItems="center">
                        <Checkbox
                            disabled={isDone || hasSubitem || isSubtaskDisabled || (!isSubitem && !isOwner && !isResponsible)}
                            className="p-0"
                            checked={item.checked}
                            tabIndex={-1}
                            disableRipple
                            name="checked"
                            onChange={handleChange}
                            style={{ marginBottom: 10 }}
                        />
                    </Grid>
                    {/* Dos filas: nombre de tarea, y abajo el slider con el % */}
                    <Grid container direction="row" item md={5} xs={10}>
                        <Grid item xs={11}>
                            <TextField
                                disabled={isDone || (!isSubitem && !isOwner) || isSubtaskDisabled}
                                className={clsx("flex flex-1 whiteText", item.checked && "line-through")}
                                name="text"
                                value={item.label}
                                onChange={handleChange}
                                InputProps={{ disableUnderline: true }}
                                multiline
                                rowsMax={2}
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item md={10} xs={11}>
                                <Tooltip title={item.date || ""}>
                                    <div style={{ width: "90%" }}>
                                        <Slider
                                            disabled={isDone || hasSubitem || isSubtaskDisabled || (!isSubitem && !isOwner && !isResponsible)}
                                            value={item.percentage}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </Tooltip>
                            </Grid>
                            <Grid item md={2} xs={1}>
                                <Typography className="whiteText" variant="subtitle1">{`${item.percentage}%`}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Datepicker, foto responsable, boton subtareas, boton borrar */}
                    <Grid container justify="space-between" item md={6} xs={12}>
                        <Grid container alignItems="center" justify="center" item xs={12} sm={isSubitem ? 9 : 5}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <DatePicker
                                    invalidLabel=""
                                    maxDateMessage=""
                                    minDateMessage=""
                                    style={{ minWidth: 145, marginBottom: 35 }}
                                    cancelLabel={getLocalizedString("cancel")}
                                    okLabel={getLocalizedString("ok")}
                                    label={getLocalizedString("endDate")}
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    minDate={endDateMinDate || MIN_DATE_DEFAULT}
                                    maxDate={endDateMaxDate || MAX_DATE_DEFAULT}
                                    value={endDateFormatted}
                                    disabled={isDone || (!isSubitem && !isOwner) || isSubtaskDisabled}
                                    onChange={(date) => handleChange(null, null, null, null, date)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {
                            !isSubitem && isShared &&
                            <Grid container alignItems="center" justify="center" item xs={3}>
                                <Grid container item justify="center" alignItems="center">
                                    <Tooltip arrow title={
                                        <Grid container direction="column">
                                            <Grid container item alignItems="center" justify="center" style={{ fontStyle: "italic" }}>
                                                {responsibleName || getLocalizedString("notAssigned")}
                                            </Grid>
                                            <Grid container item alignItems="center" justify="center">
                                                {getResponsibleTooltipText()}
                                            </Grid>
                                        </Grid>
                                    }
                                    >
                                        <div
                                            style={{ cursor: (isDone || (!isSubitem && !isOwner && !isResponsible) || isSubtaskDisabled) ? "" : "pointer" }}
                                            onClick={(isDone || (!isSubitem && !isOwner && !isResponsible) || isSubtaskDisabled) ? null : handleOpenResponsibleDialog}>
                                            <ProfileImg image={profileImg} size={40} />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                        <Grid container alignItems="center" justify={isSubitem ? "center" : "space-evenly"} item xs={isSubitem ? 3 : 4}>
                            {
                                !isSubitem &&
                                <Grid container alignItems="center" justify="center" item xs={4}>
                                    <Tooltip arrow title={
                                        <Grid container direction="column">
                                            <Grid container item alignItems="center" justify="center" style={{ fontStyle: "italic" }}>
                                                {
                                                    Boolean(item?.subitems?.length > 0) ?
                                                        `${item.subitems.length} ${getLocalizedString("subTasks")}`
                                                        : getLocalizedString("noSubtasksTooltip")
                                                }
                                            </Grid>
                                            <Grid container item alignItems="center" justify="center">
                                                {getLocalizedString("openSubtasks")}
                                            </Grid>
                                        </Grid>
                                    }
                                    >
                                        <span>
                                            <IconButton
                                                disabled={item.percentage === 100 && !hasSubitem}
                                                className="w-32 h-32 mx-4 p-0" aria-label="Delete"
                                                onClick={handleOpenSubitemDialog}
                                            >
                                                <FormatListBulleted fontSize="small" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            }
                            {
                                level !== 'C' && !isSubitem &&
                                <Grid container alignItems="center" justify="center" item xs={4}>
                                    <IconButton
                                        disabled={isMedalTableDisabled}
                                        className="w-32 h-32 mx-4 p-0"
                                        aria-label="Delete"
                                        onClick={() => setMedalTableIsOpen(true)}
                                    >
                                        <MedalTableIcon style={{ fill: isMedalTableDisabled ? "#adadad" : color }} fontSize="small" />
                                    </IconButton>
                                </Grid>
                            }
                            <Grid container alignItems="center" justify="center" item xs={4}>
                                <IconButton
                                    disabled={(isDone || (!isSubitem && !isOwner) || isSubtaskDisabled)}
                                    className="w-32 h-32 mx-4 p-0"
                                    aria-label="Delete"
                                    onClick={handleListItemRemove}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>
            {
                medalTableIsOpen &&
                <MedalTable
                    dialog
                    userCode={item.responsible}
                    handleClose={() => setMedalTableIsOpen(false)}
                />
            }
        </>
    )
}

export default CheckListItem;
