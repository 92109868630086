import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { getLocalizedString } from "@icarius-localization/strings";
import MembersDialog from "@icarius-pages/groups/components/dialogs/membersDialog";

const NoteListItemShare = ({ color, societyPeople, groups, note }) => {
    const [membersDialogIsOpen, setMembersDialogIsOpen] = useState(false);

    let participants = [];
    let colaboratorsArr = [];
    let groupsArr = [];

    const regexGrupos = /^G\d{8}/;
    const arr = note.sharedWith ? note.sharedWith.split(",") : [];
    const splittedValues = arr.filter(n => n);

    // Primero, tengo que dividir colaboradores de grupo
    for (let h = 0; h < splittedValues.length; h++) {
        if (regexGrupos.test(splittedValues[h])) {
            groupsArr.push(splittedValues[h]);
        } else {
            colaboratorsArr.push(splittedValues[h]);
        }
    }
    // Agrego el owner
    const owner = societyPeople.find(e => e.key === note.owner);
    if (owner) {
        participants.push(owner);
    }
    colaboratorsArr.forEach(c => {
        const colaborador = societyPeople.find(e => e.key === c);
        if (colaborador) {
            participants.push(colaborador);
        }
    })

    groupsArr.forEach(g => {
        const group = groups.find(e => e.id === g);
        if (group) {
            participants.push({ name: group.name, img: group.img, key: group.id });
        }
    })

    const newGroups = groups.map(g => {
        return { ...g, key: g.id }
    })

    const unificado = newGroups.concat(societyPeople);

    return (
        <>
            <Grid container>
                <Grid item xs={2} />
                <Grid container alignItems="center" item xs={10} onClick={(event) => { event.stopPropagation(); setMembersDialogIsOpen(true); }}>
                    <Typography style={{ fontWeight: 500, fontSize: 14, color: color, textDecoration: "underline" }}>
                        {getLocalizedString("viewCollaborators")}
                    </Typography>
                </Grid>
            </Grid>
            {
                membersDialogIsOpen &&
                <MembersDialog
                    open={membersDialogIsOpen}
                    participants={participants}
                    employees={unificado}
                    handleClose={() => setMembersDialogIsOpen(false)}
                    title={getLocalizedString("viewCollaborators")}
                />
            }
        </>
    );
}

export default NoteListItemShare;