import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { getSocietyPeople, getGroups } from "@icarius-pages/notes/selectors";
import { getLocalizedString } from '@icarius-localization/strings';
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import CheckListItem from "./CheckListItem";
import SubitemListDialog from "./SubitemListDialog";
import { formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import DeleteDialog from "./DeleteDialog";

const CheckListItemContainer = (props) => {

    const {
        handleListItemChange,
        handleListItemRemove,
        index,
        item,
        owner,
        isShared,
        sharedWith,
    } = props;

    const [responsibleDialogIsOpen, setResponsibleDialogIsOpen] = useState(false);
    const [subitemDialogIsOpen, setSubitemDialogIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

    const handleChange = useCallback((event, percentage, responsible = null, subitems = null, endDate) => {

        // si el porcentaje nuevo es 100, checked es true, sino false
        // si el evento es de checkbox y es checked, porcentaje es 100, sino el de la prop

        const newItem = {
            responsible: responsible !== null ? responsible : item.responsible,
            index: index,
            label: event?.target.type === 'textarea' ? event.target.value.replace(/\n/g, '') : item.label,
            checked: Number.isInteger(percentage) ? percentage === 100 : event?.target.type === 'checkbox' ? event.target.checked : item.checked,
            percentage: Number.isInteger(percentage) ? percentage : event?.target.type === 'checkbox' ? 100 : item.percentage,
            date: item.date,
        }

        //fecha termino
        if (endDate) {
            const newEndDate = formatDate(endDate);
            if (newEndDate !== "Invalid date") {
                newItem.endDate = newEndDate;
            }
        } else {
            newItem.endDate = item.endDate;
        }

        //subitems
        if (item.subitems && item.subitems.length > 0) {
            newItem.subitems = item.subitems;
        }

        if (subitems) {
            newItem.subitems = subitems;

            if (subitems.length > 0) {
                // si tiene subitems, saco el porcentaje promedio, y con eso seteo el porcentage y el checked
                let percentageToUse = Math.round(subitems.reduce((accumulator, subitem) => accumulator + subitem.percentage, 0) / subitems.length);
                newItem.percentage = percentageToUse;
                newItem.checked = percentageToUse === 100;
            } else {
                //si no tiene subitems
                newItem.percentage = 0;
                newItem.checked = false;
            }

        }

        handleListItemChange(newItem);
    }, [index, item, handleListItemChange])

    useEffect(() => { //si viene alguno cargado mal de la bd, marcar check y porcentaje
        if ((item.checked && item.percentage !== 100) || (item.percentage === 100 && !item.checked)) handleChange(null, 100);
    }, [item, handleChange])


    const getResponsibleTooltipText = () => {
        if (item.responsible === "") { //si no tiene responsable
            if (owner === userData.code) { //y soy el dueño
                if (item.percentage === 100) { //si esta terminado
                    return getLocalizedString("cantAssignResponsible"); //no se puede ASIGNAR
                }
                return getLocalizedString("assignResponsible"); //si no está terminado, ASIGNAR
            }
            return getLocalizedString("cantAssignResponsible"); //si no soy el dueño, no se puede ASIGNAR
        }

        if (item.responsible === userData.code) { //si soy el responsable
            if (item.percentage === 100) { //y esta terminado
                return getLocalizedString("cantChangeResponsible"); //no se puede CAMBIAR
            }
            return getLocalizedString("changeResponsible"); //se puede CAMBIAR
        }

        if (item.responsible !== userData.code) { //si no soy el responsable...
            if (owner && owner === userData.code) { //pero soy el owner
                if (item.percentage === 100) { //y esta terminado
                    return getLocalizedString("cantChangeResponsible"); //no se puede CAMBIAR
                }
                return getLocalizedString("changeResponsible"); //se puede CAMBIAR
            }
        }
        return getLocalizedString("cantChangeResponsible"); //si tiene responsable y no soy yo, NO se puede CAMBIAR        
    }

    const societyPeople = useSelector(getSocietyPeople);
    const groups = useSelector(getGroups);
    const userData = useSelector(getUserData);

    const responsible = useMemo(() => societyPeople.filter(user => user.key === item.responsible), [societyPeople, item])
    const profileImgToUse = Boolean(responsible.length > 0) ? responsible[0]["FOTOGRAFIA"] : "";
    const responsibleName = Boolean(responsible.length > 0) ? responsible[0]["name"] : null;

    const usersArray = useMemo(() => { //armar array con los miembros de la nota (incluyendo individual de cada grupo y al owner)
        // Logica para armar el array de responsables
        const regexGrupos = /^G\d{8}/;
        const arr = sharedWith.split(",");
        const splittedValues = arr.filter(n => n);
        let groupsArray = [];
        let colaboratorsArray = [];

        // Primero que nada, le tengo que sumar el owner
        colaboratorsArray.push(owner);

        // Primero, tengo que dividir colaboradores de grupo
        const splittedValuesLength = splittedValues.length;
        for (let h = 0; h < splittedValuesLength; h++) {
            if (regexGrupos.test(splittedValues[h])) {
                groupsArray.push(splittedValues[h]);
            } else {
                colaboratorsArray.push(splittedValues[h]);
            }
        }

        // Hecho esto, tengo que recorrer los grupos y buscar sus participantes
        const groupsLength = groupsArray.length;
        for (let i = 0; i < groupsLength; i++) {
            const group = groups.find(g => g.id === groupsArray[i]);
            if (group && group.isMemberOfGroup) {
                const participants = group.participants.map(p => p.key);
                colaboratorsArray = colaboratorsArray.concat(participants);
            }
        }

        // Elimino duplicados convirtiendo a Set
        colaboratorsArray = [...new Set(colaboratorsArray)];

        return societyPeople.filter(s => colaboratorsArray.includes(s.key));
    }, [societyPeople, groups, sharedWith, owner])

    const taskMinDate = useMemo(() => {
        // armar array con todas las fechas
        const dateArr = [createDateFromDDMMYYYY(item.date)];
        if (item.subitems) {
            item.subitems.forEach(subitem => {
                dateArr.push(createDateFromDDMMYYYY(subitem.endDate));
                dateArr.push(createDateFromDDMMYYYY(subitem.date));
            })
        }
        // obtener la maxima
        return new Date(Math.max(...dateArr));
    }, [item.subitems, item.date])

    if (!item) return null;

    return (
        <>
            <CheckListItem
                item={item}
                isShared={isShared}
                isOwner={((owner && owner === userData.code) || !owner)}
                isResponsible={item?.responsible !== "" && item?.responsible === userData.code}
                isDone={item.percentage === 100}
                endDateMinDate={taskMinDate}
                responsibleName={responsibleName}
                profileImg={profileImgToUse}
                getResponsibleTooltipText={getResponsibleTooltipText}
                handleChange={handleChange}
                handleListItemRemove={() => setDeleteDialogIsOpen(true)}
                handleOpenResponsibleDialog={() => setResponsibleDialogIsOpen(true)}
                handleOpenSubitemDialog={() => setSubitemDialogIsOpen(true)}
            />
            {
                responsibleDialogIsOpen &&
                <UserSelectionDialog
                    open={responsibleDialogIsOpen}
                    initialData={[item.responsible]}
                    users={usersArray}
                    title={`${getLocalizedString("assignResponsible")}: ${item.label}`}
                    selectedUsersTitle={getLocalizedString("responsible")}
                    handleClose={() => setResponsibleDialogIsOpen(false)}
                    handleSubmit={(code) => handleChange(null, null, (code?.[0] || ""))}
                    singleSelection
                />
            }
            {
                subitemDialogIsOpen &&
                <SubitemListDialog
                    open={subitemDialogIsOpen}
                    subitems={item.subitems}
                    itemTitle={item.label}
                    isOwner={((owner && owner === userData.code) || !owner)}
                    isResponsible={item?.responsible !== "" && item?.responsible === userData.code}
                    parentIsDone={item.percentage === 100}
                    parentEndDate={createDateFromDDMMYYYY(item.endDate)}
                    responsibleName={responsibleName}
                    responsibleKey={item.responsible}
                    profileImg={profileImgToUse}
                    isShared={isShared}
                    handleClose={() => setSubitemDialogIsOpen(false)}
                    handleSubmit={(subitems) => handleChange(null, null, null, subitems)}
                />
            }
            {
                deleteDialogIsOpen &&
                <DeleteDialog
                    open={deleteDialogIsOpen}
                    text={getLocalizedString("deleteTaskConfirm")}
                    handleClose={() => setDeleteDialogIsOpen(false)}
                    handleConfirm={() => {
                        handleListItemRemove(item.label);
                        setDeleteDialogIsOpen(false);
                    }}
                />
            }
        </>
    )
}

export default CheckListItemContainer;
