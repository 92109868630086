import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Card, Typography, Button, Grid, Box, CircularProgress } from '@material-ui/core';
import setDescriptionStyle from '../setDescriptionStyle';
import NoteReminderLabel from '../label/noteReminderLabel';
import NoteLabel from '../label/noteLabel';
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { CheckBoxOutlineBlankIcon, CheckBoxOutlinedIcon, LabelIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString } from "@icarius-localization/strings";
import NoteListItemShare from "./noteListItemShare";
import { getTheme } from "@icarius-pages/login/selectors";

const CustomButtonYes = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#bfbfbf",
        },
        backgroundColor: "#E5E5E5",
        color: "#FF0000",
    },
})(Button);

const CustomButtonNo = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#a10505",
        },
        backgroundColor: "#FF0000",
        marginRight: 10,
        color: "white",
        borderColor: "white"
    },
})(Button);

const NoteListItem = (props) => {

    const { note, variateDescSize, openNoteDialog, labels, color, groups, societyPeople, refuseNote, acceptNote } = props;
    const theme = useSelector(getTheme);

    const progress = useMemo(() => {
        if (!note.checklist || note.checklist.length === 0) return null;
        return note.checklist.reduce((sum, value) => sum + value.percentage, 0) / note.checklist.length;
    }, [note.checklist])

    return (
        <Card
            className={"cursor-pointer w-full rounded-8 shadow-none border-1 mb-16"}
            style={{ background: theme.theme === "light" && "var(--secondaryBackgroundColor)" }}
        >
            {
                note.shared === "N" && note.accepted === "N" &&
                <div className="p-8" style={{ backgroundColor: "#FF0000", paddingBottom: 0 }}>
                    <Typography
                        className="font-bold"
                        component="div"
                        style={{ color: "white", fontWeight: 700, marginBottom: 10 }}
                    >
                        {getLocalizedString("newSentNote")}
                    </Typography>
                    <Grid container>
                        <Grid item style={{ marginRight: 10, marginBottom: 10 }}>
                            <CustomButtonNo
                                type="button"
                                onClick={() => refuseNote(note)}
                                variant="contained">
                                {getLocalizedString("refuse")}
                            </CustomButtonNo>
                        </Grid>
                        <Grid item style={{ marginBottom: 10 }}>
                            <CustomButtonYes
                                type="button"
                                onClick={() => acceptNote(note)}
                                variant="contained">
                                {getLocalizedString("accept")}
                            </CustomButtonYes>
                        </Grid>
                    </Grid>
                </div>
            }
            {
                note.img && note.img !== "" &&
                <img
                    onClick={(() => openNoteDialog(note))}
                    src={IMAGES_ENDPOINT + note.img + "?" + Math.floor(Math.random() * 101)}
                    className="w-full block"
                    alt="note"
                />
            }
            {
                note.sharedWith !== "" &&
                <div className="p-8">
                    <Grid container alignItems="center" >
                        <Grid container item xs={2} alignItems="center">
                            <LabelIcon htmlColor={color} fontSize="large" />
                        </Grid>
                        <Grid container item xs={10} alignItems="center">
                            <Typography className="text-12 ml-auto mr-4 whiteText" style={{ fontWeight: 100 }}>
                                {note.ownerName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <NoteListItemShare note={note} groups={groups} societyPeople={societyPeople} color={color} />
                        </Grid>
                    </Grid>
                </div>
            }
            <div onClick={(() => openNoteDialog(note))}>
                {
                    note.titulo && note.titulo !== "" &&
                    <>
                        <Typography className="p-8 font-bold" component="div">
                            <div
                                className={"w-full break-words"}
                                ref={el => {
                                    setTimeout(() => setDescriptionStyle(note.titulo, el, variateDescSize, true));
                                }}>
                                {note.titulo}
                            </div>
                            <DialogTitleDivider />
                        </Typography>
                    </>
                }
                {
                    note.texto && note.texto !== "" &&
                    <Typography
                        className="p-8"
                        component="div"
                        onClick={(() => openNoteDialog(note))}
                    >
                        <div
                            className={"w-full break-words"}
                            ref={el => {
                                setTimeout(() => setDescriptionStyle(note.texto, el, variateDescSize, false));
                            }}>
                            {note.texto}
                        </div>
                    </Typography>
                }
                {
                    note.checklist && note.checklist.length > 0 &&
                    <>
                        <Grid container item alignItems="center" style={{ paddingLeft: 15, paddingTop: 10 }}>
                            <Typography className="whiteText" style={{ paddingRight: 15, textDecoration: "underline" }}>
                                {getLocalizedString("tasks")}
                            </Typography>
                            <Box position="relative" display="inline-flex">
                                <CircularProgress variant="static" value={progress} />
                                <Box
                                    top={0}
                                    left={0}
                                    bottom={0}
                                    right={0}
                                    position="absolute"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography variant="caption" className="whiteText">
                                        {`${Math.round(progress)}%`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <ul className="p-8 flex flex-wrap list-reset">
                            {
                                note.checklist.map((item, i) => (
                                    <li key={i} className="flex items-center w-full">
                                        {
                                            item.done === "Y"
                                                ? <CheckBoxOutlinedIcon className="mr-8 text-16 whiteText" color="action" />
                                                : <CheckBoxOutlineBlankIcon className="mr-8 text-16 whiteText" color="action" />
                                        }
                                        <Typography
                                            className={item.done === "Y" ? "truncate line-through" : "truncate"}
                                            style={{ color: "var(--mainText)" }}
                                        >
                                            {item.check}
                                        </Typography>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                }
                {
                    (note.labels.length > 0 || note["Fecha de revision"] !== "") &&
                    <div className="p-8 flex flex-wrap w-full">
                        <NoteReminderLabel className="mt-4 mr-4" date={note["Fecha de revision"]} />
                        {
                            note.labels.map((id, i) => {
                                return (
                                    <div key={i} style={{ margin: "0 0.4rem 0.4rem 0" }} className="flex flex-wrap label-width">
                                        <NoteLabel labelCode={id.labelCode} className="mt-4 mr-4" labels={labels} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    Boolean(note.numberOfComments) && Number(note.numberOfComments) > 0 &&
                    <Typography className="p-8">
                        {
                            note.numberOfComments === 1
                                ? getLocalizedString("numberOfCommentsSingular").replace("{number}", note.numberOfComments)
                                : getLocalizedString("numberOfComments").replace("{number}", note.numberOfComments)
                        }
                    </Typography>
                }
            </div>
        </Card>
    )
}

export default NoteListItem;