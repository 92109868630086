import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Publication from "@icarius-pages/feed/components/publication";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons/index";
import {
    getFeedbackPublicationCommentsAction,
} from "@icarius-pages/feedback/actions";
import {
    getComments,
    getPeople,
    getGroups,
    getTasks,
    getIsLoadingComments,
} from "@icarius-pages/feedback/selectors";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import FeedbackIcon from '@material-ui/icons/Feedback';

const NoteFormFeedbackIndicator = ({ value }) => {

    const [feedbackIsOpen, setFeedbackIsOpen] = useState(false);

    const comments = useSelector(getComments);
    const people = useSelector(getPeople);
    const groups = useSelector(getGroups);
    const tasks = useSelector(getTasks);
    const isLoadingComments = useSelector(getIsLoadingComments);

    const actions = {
        getComments: getFeedbackPublicationCommentsAction,
    }

    const isLoading = {
        comments: isLoadingComments,
    }

    if (!value) return null;

    return (
        <>
            <div className="flex flex-wrap items-center w-full p-8 pt-0" style={{ gap: 10 }}>
                <FeedbackIcon />
                <Typography className="text-12 whiteText" style={{ fontWeight: 100, cursor: 'pointer' }} onClick={() => setFeedbackIsOpen(true)}>
                    Feedback: <b>{value?.reference || ''}</b>
                </Typography>
            </div>
            {
                feedbackIsOpen &&
                <Dialog
                    TransitionComponent={DialogTransition}
                    open={feedbackIsOpen}
                    PaperComponent={PaperDraggable}
                    maxWidth='md'
                    fullWidth
                    onClose={() => setFeedbackIsOpen(false)}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        <div style={{ width: '96%' }}>
                            <Alert variant="filled" severity="info">
                                Para modificar este feedback o realizar comentarios, acceda a la función Mis Feedbacks desde Mi Escritorio
                            </Alert>
                        </div>
                    </DialogTitle>
                    <CloseIcon className={"dialog-close-icon icon"} onClick={() => setFeedbackIsOpen(false)} />
                    <DialogContent>
                        <Publication
                            data={value}
                            people={people}
                            groups={groups}
                            tasks={tasks}
                            comments={comments}
                            isLoading={isLoading}
                            actions={actions}
                            isFeedback
                            forceReadOnly
                        />
                    </DialogContent>
                </Dialog>

            }
        </>
    )
}

export default NoteFormFeedbackIndicator;