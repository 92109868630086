import React from 'react';
import { useSelector } from "react-redux";
import { ListItem, IconButton, TextField } from '@material-ui/core';
import { useNotesForm } from "@icarius-pages/notes/components/useNotesForm";
import { getLocalizedString } from "@icarius-localization/strings";
import { AddIcon } from "@icarius-icons";
import { formatDate } from "@icarius-utils/date";
import { getUserData } from "src/app/selectors";

const AddCheckListItem = ({ onListItemAdd, responsibleKey }) => {

    const userData = useSelector(getUserData);

    const { form, handleChange, resetForm } = useNotesForm({ text: "" });

    const isFormInValid = () => form.text === '';

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isFormInValid()) return;

        onListItemAdd({
            id: Math.ceil(Math.random() * 100000).toString(),
            label: form.text,
            checked: false,
            value: form.text,
            percentage: 0,
            responsible: responsibleKey || userData.code,
            date: formatDate(new Date()),
            endDate: formatDate(new Date()),
        });
        
        resetForm();
    }

    return <ListItem dense>
        <IconButton className="w-32 h-32 -ml-4 mr-4 p-0" disabled={isFormInValid()} onClick={(e) => handleSubmit(e)} >
            <AddIcon fontSize="small" />
        </IconButton>
        <TextField
            className="flex flex-1"
            name="text"
            value={form.text}
            onChange={handleChange}
            placeholder={getLocalizedString("addItem")}
            inputProps={{ maxLength: 100 }}
            InputProps={{ disableUnderline: true }}
            autoFocus
            multiline
            rowsMax={2}
        />
    </ListItem>
}

export default AddCheckListItem;
