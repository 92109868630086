import React, { useState } from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import UserSelectionColumn from "./column";
import DialogTransition from "@icarius-common/dialogTransition";

const UserSelectionDialog = (props) => {

  const [selectedAux, setSelectedAux] = useState([]);
  const {
    open,
    users,
    handleClose,
    handleSubmit,
    handleUserClick,
    title,
    buttonText,
    onlySelectOne,
    loggedUserIsSelectable,
  } = props;

  const handleClick = (key) => {
    if (onlySelectOne) {
      handleUserClick(key);
      setSelectedAux([key]); //va a ser un array con un solo elemento
    } else {
      handleUserClick(key);

      let auxArr = [...selectedAux];
      let index = auxArr.indexOf(key);
      if (index > -1) { //si esta, lo saca
        auxArr.splice(index, 1);
      } else { //sino, lo agrega
        auxArr.push(key);
      }
      setSelectedAux(auxArr);
    }
  }

  const handleSubmitUsers = () => {
    const usersCheckedArray = users.filter(user => user.isChecked).map(user => user.key).join(",");
    handleSubmit(usersCheckedArray);
    handleClose(null);
  }

  const checkedUsers = users ? users.filter(user => user.isChecked) : [];
  const matchesMoreOrEqualThan960 = useMediaQuery(`(min-width:960px)`);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth
      maxWidth={matchesMoreOrEqualThan960 ? "md" : "sm"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title || getLocalizedString("groupAddMembers")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={() => handleClose(selectedAux)} />
      <DialogContent>
        <Grid container direction="row" justify="space-around">
          {/* Columna 1 - participantes */}
          <UserSelectionColumn
            employees={checkedUsers}
            isActualMembersColumn={true}
            handleUserClick={handleClick}
          />
          {/* Columna 2 - todos los usuarios usuarios */}
          <UserSelectionColumn
            employees={users}
            isActualMembersColumn={false}
            handleUserClick={handleClick}
            loggedUserIsSelectable={loggedUserIsSelectable}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={() => handleClose(selectedAux)}
          text={getLocalizedString("disagree")}
        />
        <ButtonDialogAction
          onClick={handleSubmitUsers}
          isAccept
          text={buttonText || getLocalizedString("save")}
        />
      </DialogActions>
    </Dialog>
  );
}

export default UserSelectionDialog;
