import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getNotesAction,
    getNotesLabelsAction,
    createNoteAction,
    updateNoteAction,
    deleteNoteAction,
    createNotesLabelAction,
    updateNotesLabelAction,
    deleteNotesLabelAction,
    getSocietyPeopleAndGroups,
    acceptNoteAction,
    refuseNoteAction,
} from "@icarius-pages/notes/actions";
import {
    getNotes,
    getUserLabels,
    isLoading,
    getSocietyPeople,
    getGroups,
} from "@icarius-pages/notes/selectors";
import { getUserData } from "src/app/selectors";
import { getSocietySelected, getTheme } from "@icarius-pages/login/selectors";
import { formatDateYYYYMMDD } from "@icarius-utils/date";
import { getFeedbackPrivacyOptionsAction } from "@icarius-pages/feedback/actions";
import { getIsLoadingPrivacyData } from "@icarius-pages/feedback/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useNotes = () => {

    const dispatch = useDispatch();

    const notes = useSelector(getNotes);
    const labels = useSelector(getUserLabels);
    const loading = useSelector(isLoading);
    const isLoadingPrivacyData = useSelector(getIsLoadingPrivacyData);
    const groups = useSelector(getGroups);
    const societyPeople = useSelector(getSocietyPeople);
    const userData = useSelector(getUserData);
    const societySelected = useSelector(getSocietySelected);
    const gridTheme = useSelector(getTheme);

    const [selectedNote, setSelectedNote] = useState(null);
    const [filteredType, setFilteredType] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [order, setOrder] = useState("0"); //1 asc, 0 desc
    const [createLabelDialogIsOpen, setCreateLabelDialogIsOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");

    const [duplicationDialogIsOpen, setDuplicationDialogIsOpen] = useState(false);
    const [duplicationData, setDuplicationData] = useState(null);
    const [myToDoScreenIsOpen, setMyToDoScreenIsOpen] = useState(false);

    useEffect(() => {
        dispatch(getNotesAction());
        dispatch(getNotesLabelsAction());
        dispatch(getSocietyPeopleAndGroups());
        dispatch(getFeedbackPrivacyOptionsAction());
    }, [dispatch])

    const handleDuplicate = (noteData) => {
        setDuplicationDialogIsOpen(true);

        const newLabels = noteData.labels.map((label) => label.labelCode);

        const newChecklist = noteData.checklist.map((item) => {

            const itemCopy = { ...item };
            delete itemCopy.date;

            return {
                ...itemCopy,
                percentage: 0,
                done: "N",
                endDate: formatDateYYYYMMDD(new Date()), //aca va la fecha de hoy
                responsible: userData.code, //aca va mi code
                subitems: itemCopy.subitems.map((subitem) => {

                    const subitemCopy = { ...subitem };
                    delete subitemCopy.date;

                    return {
                        ...subitemCopy,
                        percentage: 0,
                        done: "N",
                        endDate: formatDateYYYYMMDD(new Date()), //aca va la fecha de hoy
                        responsible: userData.code, //aca va mi code
                    }
                })
            }
        })

        const newNote = {
            archiv: "N",
            attachments: null,
            checklist: newChecklist.length !== 0 ? newChecklist : null,
            fecrev: "",
            img: "",
            labels: newLabels.length !== 0 ? newLabels : "",
            texto: noteData.texto,
            titulo: noteData.titulo,
        }

        setDuplicationDialogIsOpen(true);
        setDuplicationData(newNote);
    }

    const handleCloseDuplicationDialog = () => {
        setDuplicationDialogIsOpen(false);
        setDuplicationData(null);
    }

    const createNewNote = (data) => {
        dispatch(createNoteAction(data)).then(resp => resp && resp.data.status === "OK" && setSelectedNote(null))
    }

    const handleEditNote = (data) => {
        dispatch(updateNoteAction(data)).then(resp => resp && resp.data.status === "OK" && setSelectedNote(null))
    }

    const handleDeleteNote = () => {
        const auxNote = { ...selectedNote };
        const message = (() => {
            if (!auxNote.feedback) return getLocalizedString("confirmDeleteNote");
            return (
                <>
                    {`Esta tarea está vinculada al feedback: ${auxNote.feedback.reference}`}
                    <br/>
                    <br/>
                    Si elimina la tarea, también se eliminará el feedback completo y todo lo que este contenga (texto, referencia, evidencia y comentarios)
                </>
            )
        })();

        dispatch(openDialogAction({
            title: 'Atención',
            msg: message,
            onConfirm: () => {
                //lo sacamos de seleccionado aca, si lo dejamos adentro del then, mientras carga el delete pueden romper cosas
                setSelectedNote(null);

                dispatch(deleteNoteAction({ codNota: auxNote.codNota }))
                    .then(resp => {
                        if (resp?.data?.status !== "OK") {
                            setSelectedNote(auxNote); //si falla el eliminar, lo volvemos a dejar seleccionado
                        }
                    })

            }
        }))

    }

    const createNewLabel = (label) => {
        return dispatch(createNotesLabelAction(label));
    }

    const editLabel = (data) => {
        dispatch(updateNotesLabelAction(data));
    }

    const removeLabel = (data) => {
        dispatch(deleteNotesLabelAction(data))
            .then(() => {
                setFilteredType('');
            })
    }

    const acceptNote = (data) => {
        dispatch(acceptNoteAction(data)).then(resp => resp && resp.data.status === "OK" && setSelectedNote(null))
    }

    const refuseNote = (data) => {
        dispatch(refuseNoteAction(data)).then(resp => resp && resp.data.status === "OK" && setSelectedNote(null))
    }

    const openNoteFromMyToDo = code => {
        const foundNote = notes.find(n => n.codNota === code);
        if (foundNote) {
            setSelectedNote(foundNote);
        }
    }

    const notesData = {
        loading: loading || isLoadingPrivacyData,
        notes,
        labels,
        groups,
        societyPeople,
        userData,
        societySelected,
        gridTheme,
        selectedNote,
        filteredType,
        showArchived,
        order,
        createLabelDialogIsOpen,
        searchInput,
        duplicationDialogIsOpen,
        duplicationData,
        myToDoScreenIsOpen,
    };

    const notesFunctions = {
        handleDuplicate,
        handleCloseDuplicationDialog,
        createNewNote,
        handleEditNote,
        handleDeleteNote,
        createNewLabel,
        editLabel,
        removeLabel,
        acceptNote,
        refuseNote,
        openNoteFromMyToDo,
        setSelectedNote,
        setFilteredType,
        setShowArchived,
        setOrder,
        setCreateLabelDialogIsOpen,
        setSearchInput,
        setDuplicationDialogIsOpen,
        setDuplicationData,
        setMyToDoScreenIsOpen,
    };

    return {
        notesData,
        notesFunctions,
    };
}

export default useNotes;
