import React from "react";
import { DialogTitle, Dialog, DialogContent } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { withStyles } from "@material-ui/core/styles";
import PaperDraggable from "@icarius-common/paperDraggable";
import TasksGridDialogContent from "./tasksGridDialogContent";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const TasksGridDialog = (props) => {

  const {
    open,
    data,
    dateFormat,
    handleSendMail,
    handleClose,
  } = props;    

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {getLocalizedString("tasks")}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <TasksGridDialogContent
          handleSendMail={handleSendMail}
          data={data}
          dateFormat={dateFormat}
          isGeneralGrid
        />          
      </DialogContent>
    </StyledDialog>
  );
}

export default TasksGridDialog;
