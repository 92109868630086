import React from 'react';
import { Grid } from '@material-ui/core';
import { Form } from "react-final-form";
import TagFormList from "../taglist/TagFormList";

class LabelForm extends React.Component {

    handleChecklistChange = (tags) => {
        window.setFormValue("tags", tags);
    }

    validateAndConfirm = (values) => {
        if (values["nombre"]) {
            let data = {
                "nombre": values.nombre,
            };
            this.props.handleCreateLabel(data);
        }
    }

    addItem = (item) => {
        if (item) {
            return this.props.handleCreateLabel(item)
        } else {
            return null;
        }
    }

    editItem = (item) => {
        item && this.props.handleEditLabel(item)
    }

    removeItem = (labelCode) => {
        labelCode && this.props.handleRemoveLabel(labelCode)
    }

    render() {
        const initialValues = {
            tags: this.props.tags,
        };

        return (
            <Form
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                initialValues={initialValues}
                onSubmit={this.validateAndConfirm}
                render={({ handleSubmit, form, submitting, pristine, values, active }) => {
                    window.setFormValue = form.mutators.setValue;
                    return (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <Grid container direction="row" justify="center">
                                <TagFormList
                                    name="tags"
                                    tags={values.tags}
                                    onCheckListChange={this.handleChecklistChange}
                                    editItem={this.editItem}
                                    removeItem={this.removeItem}
                                    addItem={this.addItem}
                                />
                            </Grid>
                        </form>
                    )
                }}
            />
        )
    }
}

export default LabelForm;
