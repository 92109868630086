import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../login/selectors";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import UserSection from "./userSection";
import { SearchIcon } from "@icarius-icons/";

const UserSelectionColumn = (props) => {

  const [userInput, setUserInput] = useState(""); //para el input en si, e ir actualizando letra a letra
  const [searchValue, setSearchValue] = useState(""); //el texto del input cuando el user deja de escribir, es lo que se le envia a UserSection para filtrar

  const {
    employees,
    isActualMembersColumn,
    handleUserClick,
    loggedUserIsSelectable,
  } = props;

  const theme = useSelector(getTheme);
  const filterBarColor = theme.theme === "dark" ? "#747474" : "#949494";

  useEffect(() => {
    //para poner delay al seteado de searchValue y que no filtre letra por letra, sino cuando ya deje de escribir
    const timeOutId = setTimeout(() => setSearchValue(userInput), 500);
    return () => clearTimeout(timeOutId);
  }, [userInput]);

  return (
    <Grid container direction="row" item xs={12} md={5} style={{ marginBottom: 30 }}>
      <Grid item xs={12} style={{ paddingBottom: 20 }}>
        {
          isActualMembersColumn ?
            <div style={{ display: "flex", alignItems: "center", height: 32 }}>
              <Typography variant="h6" className="whiteText" style={{ fontWeight: 300, fontSize: 18 }}>
                {getLocalizedString("groupCurrentMembers")}
              </Typography>
              <Typography variant="h6" className="whiteText" style={{ fontWeight: 700, fontSize: 18, marginLeft: 20 }}>
                {employees.length}
              </Typography>
            </div>
            :
            <div style={{ display: "flex", alignItems: "center", height: 32, width: 240 }}>
              <TextField
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => setUserInput(e.target.value)}
                style={{ borderBottom: `1px solid ${filterBarColor}` }}
                placeholder={getLocalizedString("societyPeopleAndGroupsSearchLabel")}
                type="text"
                value={userInput} />
            </div>
        }
      </Grid>
      <Grid item xs={12}>
        <UserSection
          searchValue={searchValue}
          employees={employees}
          isActualMembersColumn={isActualMembersColumn}
          handleUserClick={handleUserClick}
          loggedUserIsSelectable={loggedUserIsSelectable}
        />
      </Grid>
    </Grid>
  );
}

export default UserSelectionColumn;
