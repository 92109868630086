import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { HorizontalBar } from 'react-chartjs-2';
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import moment from 'moment';
import { formatNumberExactDecimals } from "@icarius-utils/format";

const TaskListItem = ({ task, gridTheme, color, isLastMember, openNoteDialog }) => {
    const { check, endDate, percentage, noteTitle } = task;

    let colorToUse = "#FF4040";
    if (percentage > 40 && percentage <= 70) {
        colorToUse = "#F5A506";
    } else if (percentage > 70) {
        colorToUse = "#00993D";
    }

    const chartOptions = {
        legend: {
            display: false,
        },
        scales: {
            xAxes: [{
                display: false,
            }],
            yAxes: [{
                display: false,
            }]
        },
        tooltips: {
            enabled: false
        }
    }

    const chartData = {
        datasets: [
            {
                label: "Finalizado",
                stack: "stack",
                data: [percentage],
                backgroundColor: [colorToUse],
                borderWidth: 1,
            },
            {
                label: "Faltante",
                stack: "stack",
                data: [100 - percentage],
                backgroundColor: ["#747474"],
                borderWidth: 1,
            },
        ],
    }

    const getStatusLabelAndColor = () => {

        const today = new Date();
        const taskDate = createDateFromDDMMYYYY(endDate);

        if (percentage === 100) {
            return {
                label: "Finalizada",
                labelColor: "#00993D",
            }
        }

        if (moment(taskDate).isSame(moment(), 'day')) {
            return {
                label: "Hoy finaliza el plazo",
                labelColor: "#FF592C",
            }
        }

        if (moment(taskDate).isAfter(moment())) {
            const given = moment(taskDate).startOf('day');
            const current = moment(today).startOf('day');

            const daysDiffence = moment.duration(given.diff(current)).asDays().toFixed(0);

            return {
                label: `Quedan ${daysDiffence} días`,
                labelColor: "#00A3BE",
            }
        }

        const given = moment(taskDate).startOf('day');
        const current = moment(today).startOf('day');
        let auxLabel;

        const daysDiffence = formatNumberExactDecimals(Math.abs(moment.duration(given.diff(current)).asDays()), 0);

        if (daysDiffence === 1) {
            auxLabel = `Atraso de ${daysDiffence} día`;
        } else {
            auxLabel = `Atraso de ${daysDiffence} días`;
        }

        return {
            label: auxLabel,
            labelColor: "#FF4040",
        }
    }

    const { label, labelColor } = getStatusLabelAndColor();

    return (
        <Grid
            container
            direction='row'
            item
            xs={12}
            alignItems="center"
            justify="center"
            style={isLastMember ? { padding: '20px 0px', marginBottom: 10 } : { padding: '20px 0px', borderBottom: `1px solid ${color}`, marginBottom: 10 }}
            onClick={() => openNoteDialog(task.codNota)}>
            <Grid item sm={4} xs={12}>
                <Typography className='whiteText' style={{ fontSize: 14, fontWeight: 500, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                    {noteTitle}
                </Typography>
                <Typography className='whiteText' style={{ fontSize: 14, fontWeight: 900, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                    {check}
                </Typography>
                <Typography className='whiteText' style={{ fontSize: 13, fontWeight: 400, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                    {endDate}
                </Typography>
            </Grid>
            <Grid container direction="row" item sm={6} xs={12} justify="space-between" alignItems="center">
                <Grid item sm={4} xs={12}>
                    <div style={{ marginLeft: 10, width: 160, height: 60 }}>
                        <HorizontalBar data={chartData} options={chartOptions} width={160} height={60} />
                    </div>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <Typography className='whiteText' style={{ padding: "10px 20px", fontSize: 16, fontWeight: 500, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                        {`${percentage}%`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" item sm={2} xs={12}>
                <Typography style={{ color: labelColor, fontSize: 16, fontWeight: 500, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                    {label}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default TaskListItem;