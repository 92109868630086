import React from "react";
import { Doughnut } from 'react-chartjs-2';
import { Grid, Typography } from "@material-ui/core";
import { getUserData } from "src/app/selectors";
import { useSelector } from "react-redux";

const GlobalProgress = ({ notes, gridTheme, userCode, showAllTasks, societyPeople }) => {
    const userData = useSelector(getUserData);
    const userTasks = userCode || userData.code;

    const getTasksData = () => {
        let percentageSum = 0;
        let amountElements = 0;
        let hasOwnTasks = false;

        notes.forEach(note => (
            note.checklist.forEach(task => {
                if (task.responsible === userTasks) {
                    if ((showAllTasks || task.percentage !== 100)) {
                        percentageSum += task.percentage;
                        amountElements++;
                    }
                    if (!hasOwnTasks && task.responsible === userData.code) {
                        hasOwnTasks = true;
                    }
                }
            })
        ))

        return { percentageSum, amountElements, hasOwnTasks };
    }

    const { percentageSum, amountElements, hasOwnTasks } = getTasksData();
    const result = amountElements ? (percentageSum / amountElements).toFixed(2) : (0).toFixed(2);

    let colorToUse = "#FF4040";
    if (result > 40 && result <= 70) {
        colorToUse = "#F5A506";
    } else if (result > 70) {
        colorToUse = "#00993D";
    }

    const chartOptions = {
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false
        }
    }

    const chartData = {
        labels: ["Finalizado", 'Faltante'],
        datasets: [
            {
                data: [result, 100 - result],
                backgroundColor: [colorToUse, gridTheme.theme === "dark" ? "#121212" : "#EEEEEE"],
                borderColor: [colorToUse, colorToUse],
                borderWidth: 1,
            },
        ],
    }

    // Label de progreso global
    let globalProgress = "Mi progreso global";
    if (userCode && userCode !== userData.code) {
        const element = societyPeople.find(e => e.key === userCode);
        if (element) {
            globalProgress = `Progreso global de ${element.name}`;
        }
    }

    if (amountElements > 0) {
        return (
            <Grid container direction='row' item xs={12} alignItems="center" style={{ padding: '20px 0px' }}>
                <Grid item lg={3} md={6} xs={12}>
                    <div style={{ width: 200, height: 130 }}>
                        <Doughnut data={chartData} options={chartOptions} width={200} height={130} />
                    </div>
                </Grid>
                <Grid container direction='column' item lg={9} md={6} xs={12} justify="center">
                    <Typography className='whiteText' style={{ fontSize: 36, paddingBottom: 20, fontWeight: 400, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                        {`${result}%`}
                    </Typography>
                    <Typography className='whiteText' style={{ fontSize: 24, paddingBottom: 20, fontWeight: 700, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                        {globalProgress}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    if (userCode !== null || hasOwnTasks) {
        return (
            <Typography className='whiteText' style={{ fontSize: 24, paddingBottom: 20, fontWeight: 700, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
                {globalProgress}
            </Typography>
        )
    }

    return (
        <Typography className='whiteText' style={{ fontSize: 24, paddingBottom: 20, fontWeight: 700, textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : '' }}>
            {'Seleccione un Colaborador para ver sus avances'}
        </Typography>
    )
}

export default GlobalProgress;