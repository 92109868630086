import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.notesDialog],
        config: {
            headerName: 'Propietario de la tarea',
            field: "owner",
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: 'Progreso global de la tarea',
            field: "globalProgress",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "employeeCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: 'Apellido y nombre del responsable',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: 'Tarea asignada',
            field: "task",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("taskProgress"),
            field: "taskProgress",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("subtask"),
            field: "subtask",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("subtaskProgress"),
            field: "subtaskProgress",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("taskUpdateDate"),
            field: "date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            cellRenderer: "NoteItemStatusRenderer",
            filter: "NoteItemStatusFilter",
        }
    },
    {
        pages: [paths.notesDialog],
        config: {
            headerName: getLocalizedString("endDate"),
            field: "endDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: 'Tarea',
            field: "note",
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: 'Propietario de la tarea',
            field: "owner",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: 'Progreso global de la tarea',
            field: "globalProgress",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "employeeCode",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: 'Apellido y nombre del responsable',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: 'Tarea asignada',
            field: "task",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("taskProgress"),
            field: "taskProgress",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("subtask"),
            field: "subtask",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("subtaskProgress"),
            field: "subtaskProgress",
            cellRenderer: "ProgressRenderer",
            filter: "ProgressFilter",
            cellClass: "percentageValue",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("taskUpdateDate"),
            field: "date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("status"),
            field: "status",
            cellRenderer: "NoteItemStatusRenderer",
            filter: "NoteItemStatusFilter",
        }
    },
    {
        pages: [paths.notes],
        config: {
            headerName: getLocalizedString("endDate"),
            field: "endDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
]