import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import * as actionTypes from "./actionTypes";
import {
  getNotesAPI,
  getNotesLabelsAPI,
  createNoteAPI,
  updateNoteAPI,
  deleteNoteAPI,
  createNotesLabelAPI,
  deleteNotesLabelAPI,
  updateNotesLabelAPI,
  getNoteCommentsAPI,
  getSocietyPeopleAndGroupsEndpoint,
  createNoteCommentAPI,
  updateNoteCommentAPI,
  deleteNoteCommentAPI,
  uploadNoteAttachmentAPI,
  deleteNoteAttachmentAPI,
  sendNoteAPI,
  shareNoteAPI,
  confirmNoteAPI,
  sendNoteTaskReminderAPI
} from "@icarius-connection/api";
import {
  getLocalizedErrorString,
  getLocalizedString,
} from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "NOTE_NOT_FOUND":
      errorString = getLocalizedString("noteNotFound");
      break;
    case "COMMENT_NOT_FOUND":
      errorString = getLocalizedString("commentNotFound");
      break;
    case "INVALID_PARTICIPANTS":
      errorString = getLocalizedString("invalidParticipants");
      break;
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "INVALID_GROUPS":
      errorString = getLocalizedString("invalidGroups");
      break;
    case "INVALID_COLABORATORS":
      errorString = getLocalizedString("invalidColaborators");
      break;
    case "INVALID_COLABORATORS_GROUPS":
      errorString = getLocalizedString("invalidColaboratorsGroups");
      break;
    case "NO_FILES_UPLOADED":
      errorString = getLocalizedString("noFilesUploaded");
      break;
    case "INVALID_FILE":
      errorString = getLocalizedString("invalidFile");
      break;
    case "IMAGE_SIZE_EXCEEDED":
      errorString = getLocalizedString("imageSizeExceeded");
      break;
    case "FILE_SIZE_EXCEEDED":
      errorString = getLocalizedString("fileSizeExceeded");
      break;
    case "FILE_LIMIT_EXCEEDED":
      errorString = getLocalizedString("fileLimitExceeded");
      break;
    case "FAILED_OPERATION":
      errorString = getLocalizedString("attachmentFailedOperation");
      break;
    case "UNAUTHORIZED":
      errorString = getLocalizedString("unauth");
      break;
    case "INVALID_DATES":
      errorString = getLocalizedString("noteInvalidDates");
      break;
    case "INVALID_RESPONSIBLE":
      errorString = getLocalizedString("noteInvalidResponsible");
      break;
    case "IN_USE":
      errorString = `No puede eliminarse porque el feedback asociado está relacionado con la evaluación de desempeño: ${e.response?.data?.name}`;
      break;
    case "IN_USE_GOALS":
      errorString = 'No es posible eliminar la tarea porque está en uso en las metas y objetivos de los colaboradores';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getNotesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_NOTES });
  try {
    let response = await getNotesAPI();

    let notes = response.data && response.data.notes;
    let status = response.data && response.data.status;
    let dateFormat = response.data && response.data.date_format;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_NOTES_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.GET_NOTES_FULFILLED,
      payload: {
        notes,
        dateFormat,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_NOTES_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const shareNoteAction = (code, participants, groups) => async (dispatch) => {
  dispatch({ type: actionTypes.SHARE_NOTE });
  try {
    let response = await shareNoteAPI({ code: code, colaborators: participants.join(","), groups: groups.join(",") });

    let status = response.data && response.data.status;
    let note = response.data && response.data.note;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SHARE_NOTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({ type: actionTypes.SHARE_NOTE_FULFILLED, payload: { note } });
    dispatch(openSnackbarAction({ msg: getLocalizedString("notaSharedOk"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SHARE_NOTE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const sendNoteAction = (code, participants, groups) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_NOTE });
  try {
    let response = await sendNoteAPI({ code: code, colaborators: participants.join(","), groups: groups.join(",") });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SEND_NOTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({ type: actionTypes.SEND_NOTE_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("notaSentOk"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_NOTE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const acceptNoteAction = (note) => async (dispatch) => {
  dispatch({ type: actionTypes.ACCEPT_NOTE });
  try {
    let response = await confirmNoteAPI({ code: note.codNota, status: true });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ACCEPT_NOTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.ACCEPT_NOTE_FULFILLED,
      payload: { note: note }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ACCEPT_NOTE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const refuseNoteAction = (note) => async (dispatch) => {
  dispatch({ type: actionTypes.REFUSE_NOTE });
  try {
    let response = await confirmNoteAPI({ code: note.codNota, status: false });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.REFUSE_NOTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.REFUSE_NOTE_FULFILLED,
      payload: { note: note }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.REFUSE_NOTE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const uploadNoteAttachmentAction = (file) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_ATTACHMENT });
  try {
    let formData = new FormData();
    formData.append('file', file)
    let response = await uploadNoteAttachmentAPI(formData);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPLOAD_ATTACHMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_ATTACHMENT_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_ATTACHMENT_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const deleteNoteAttachmentAction = (file) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_ATTACHMENT });
  try {
    let response = await deleteNoteAttachmentAPI({ filename: file });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_ATTACHMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_ATTACHMENT_FULFILLED
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_ATTACHMENT_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const createNoteCommentAction = (codNota, text) => async (dispatch) => {
  dispatch({ type: actionTypes.ADD_NEW_COMMENT });
  try {
    let response = await createNoteCommentAPI({ noteCode: codNota, text: text });

    let result = response.data && response.data.result[0];
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.ADD_NEW_COMMENT_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.ADD_NEW_COMMENT_FULFILLED,
      payload: {
        comment: result,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.ADD_NEW_COMMENT_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const updateNoteCommentAction = (code, text) => async (dispatch) => {
  dispatch({ type: actionTypes.EDIT_COMMENT });
  try {
    let response = await updateNoteCommentAPI({ code, text });

    let result = response.data && response.data.result[0];
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EDIT_COMMENT_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.EDIT_COMMENT_FULFILLED,
      payload: {
        comment: result,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EDIT_COMMENT_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const getNoteCommentsAction = (note) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COMMENTS_FOR_NOTE });
  try {
    let response = await getNoteCommentsAPI(`?noteCode=${note}`);

    let comments = response.data && response.data.comments;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_COMMENTS_FOR_NOTE_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.GET_COMMENTS_FOR_NOTE_FULFILLED,
      payload: {
        comments: comments,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COMMENTS_FOR_NOTE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const clearCommentsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_COMMENTS });
}

export const deleteNoteCommentAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COMMENT });
  try {
    let response = await deleteNoteCommentAPI({ code: code });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.DELETE_COMMENT_FULFILLED,
      payload: {
        code: code,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const getSocietyPeopleAndGroups = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SOCIETY_AND_GROUPS });
  try {
    let response = await getSocietyPeopleAndGroupsEndpoint();

    let groups = response.data && response.data.groups;
    let societyPeople = response.data && response.data.societyPeople;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_SOCIETY_AND_GROUPS_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_SOCIETY_AND_GROUPS_FULFILLED,
      payload: {
        societyPeople: societyPeople,
        groups: groups
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_SOCIETY_AND_GROUPS_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const getNotesLabelsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_LABELS });
  try {
    let response = await getNotesLabelsAPI();

    let labels = response.data && response.data.labels;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_LABELS_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_LABELS_FULFILLED,
      payload: {
        labels: labels,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_LABELS_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createNoteAction = (note) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_NOTE });
  try {
    let response = await createNoteAPI(note);
    let status = response.data && response.data.status;
    let data = response.data && response.data.data;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_NOTE_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_NOTE_FULFILLED,
      payload: {
        data: data,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_NOTE_REJECTED });
    if (e.response.status === 413) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("imageSizeExceeded"), severity: "error" }));
    } else {
      errorHandler(e, dispatch);
    }
  }
};

export const updateNoteAction = (note) => async (dispatch) => {
  dispatch({ type: actionTypes.EDIT_NOTE });
  try {
    let response = await updateNoteAPI(note);
    let status = response.data && response.data.status;
    let data = response.data && response.data.note;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EDIT_NOTE_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EDIT_NOTE_FULFILLED,
      payload: {
        data: data,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EDIT_NOTE_REJECTED });
    if (e.response.status === 413) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("imageSizeExceeded"), severity: "error" }));
    } else {
      errorHandler(e, dispatch);
    }
  }
};

export const deleteNoteAction = (noteCode) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_NOTE });
  try {
    let response = await deleteNoteAPI(noteCode);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_NOTE_REJECTED });

      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_NOTE_FULFILLED,
      payload: {
        removedNote: noteCode,
      },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_NOTE_REJECTED });
    errorHandler(e, dispatch);
  }
};

export const createNotesLabelAction = (label) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_LABEL });
  try {
    let response = await createNotesLabelAPI(label);
    let status = response.data && response.data.status;
    let data = response.data && response.data.label.data;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_LABEL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_LABEL_FULFILLED,
      payload: {
        data: data,
      },
    });
    dispatch(openSnackbarAction({ msg: getLocalizedString("labelCorrectlyCreated"), severity: "success" }));
    return response;
  } catch (e) {
    if (e.response.data.status === "LABEL_ALREADY_EXISTS") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("tagRepeated"), severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_LABEL_REJECTED });
  }
}

export const updateNotesLabelAction = (label) => async (dispatch) => {
  dispatch({ type: actionTypes.EDIT_LABEL });
  try {
    let response = await updateNotesLabelAPI(label);
    let status = response.data && response.data.status;
    let data = response.data && response.data.label.data;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EDIT_LABEL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EDIT_LABEL_FULFILLED,
      payload: {
        data: data,
      },
    });
    dispatch(openSnackbarAction({ msg: getLocalizedString("labelCorrectlyModified"), severity: "success" }));
    return response;
  } catch (e) {
    if (e.response.data.status === "LABEL_ALREADY_EXISTS") {
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("tagRepeated"), severity: "error" }));
    } else if (e.response.data.status === "LABEL_NOT_MODIFIED") {
      //Nada
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }

    dispatch({ type: actionTypes.EDIT_LABEL_REJECTED });
  }
}

export const deleteNotesLabelAction = (label) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_LABEL });
  try {
    let response = await deleteNotesLabelAPI({ labelCode: label });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_LABEL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_LABEL_FULFILLED,
      payload: {
        data: label,
      },
    });
    dispatch(openSnackbarAction({ msg: getLocalizedString("labelCorrectlyDeleted"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: actionTypes.DELETE_LABEL_REJECTED });
  }
};

export const sendNoteTaskReminderAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_TASK_REMINDER });
  try {
    dispatch(openSnackbarAction({ msg: getLocalizedString("sendingMail"), severity: "info" }));

    let response = await sendNoteTaskReminderAPI({ tasks: data });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SEND_TASK_REMINDER_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({ type: actionTypes.SEND_TASK_REMINDER_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("noteMailSent"), severity: "success" }));

    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: actionTypes.SEND_TASK_REMINDER_REJECTED });
  }
};