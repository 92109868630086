import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { Grid, Typography } from "@material-ui/core";
import moment from 'moment';
import TaskListItem from "./taskListItem";

const useTasks = (notes, userCode, showAllTasks) => {
    const [isSortedByTasks, setIsSortedByTasks] = useState(false);
    const [tasks, setTasks] = useState([]);

    const userData = useSelector(getUserData);
    const userTasks = userCode || userData.code;

    useEffect(() => {
        const compareDate = (dateTimeA, dateTimeB) => {
            let momentA = moment(dateTimeA, "DD/MM/YYYY");
            let momentB = moment(dateTimeB, "DD/MM/YYYY");
            if (momentA > momentB) return 1;
            else if (momentA < momentB) return -1;
            else return 0;
        }

        // Armo array de tasks
        let auxTasks = [];
        notes.forEach(note => {
            note.checklist.forEach(task => {
                auxTasks.push({ ...task, noteTitle: note.titulo, codNota: note.codNota })
            })
        })
    
        // Voy a filtrar por el responsable
        auxTasks = auxTasks.filter(task => task.responsible === userTasks);
    
        // Depende el modo, oculto las tareas finalizadas
        if (!showAllTasks) {
            auxTasks = auxTasks.filter(task => task.percentage < 100)
        }
    
        // Lo ordeno
        if (isSortedByTasks) {
            auxTasks = auxTasks.sort((a, b) => a.noteTitle.localeCompare(b.noteTitle) || a.check.localeCompare(b.check) || compareDate(a.endDate, b.endDate));
        } else {
            auxTasks = auxTasks.sort((a, b) => b.percentage - a.percentage);
        }

        setTasks(auxTasks);
    }, [notes, isSortedByTasks, showAllTasks, userTasks])

    const getTitleString = () => {
        if(Boolean(userCode) && userCode !== userData.code){
            return !showAllTasks ? 'Tareas pendientes' : 'Tareas';
        }
        return !showAllTasks ? 'Mis tareas pendientes' : 'Mis tareas'
    }

    const toggleSort = () => {
        setIsSortedByTasks(prev => !prev);
    }

    return {
        tasks,
        isSortedByTasks,
        toggleSort,
        getTitleString,
    }
}

const PendingTasks = ({ notes, gridTheme, openNoteDialog, color, userCode, showAllTasks }) => {
    const {
        tasks,
        isSortedByTasks,
        toggleSort,
        getTitleString,
    } = useTasks(notes, userCode, showAllTasks);

    if (tasks.length === 0) {
        return (
            <Grid container direction='row' item xs={12} alignItems="flex-start" justify="center" style={{ padding: '0px', marginBottom: 20 }}>
                <Grid container direction='row' style={{ padding: '0px 50px' }}>
                    <Grid item xs={6}>
                        <Typography
                            className='whiteText'
                            style={{
                                fontSize: 18,
                                paddingBottom: 20,
                                fontWeight: 700,
                                textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : ''
                            }}
                        >
                            {'No hay tareas pendientes'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container direction='row' item xs={12} alignItems="flex-start" justify="center" style={{ padding: '0px', marginBottom: 20 }}>
            <Grid container direction='row' style={{ padding: '0px 50px' }}>
                <Grid item xs={6}>
                    <Typography
                        className='whiteText'
                        style={{
                            fontSize: 18,
                            paddingBottom: 20,
                            fontWeight: 700,
                            textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : ''
                        }}
                    >
                        {getTitleString()}
                    </Typography>
                </Grid>
                <Grid container justify="flex-end" item xs={6} >
                    <Typography className='whiteText'
                        onClick={toggleSort}
                        style={{
                            cursor: "pointer",
                            fontSize: 14,
                            paddingBottom: 20,
                            fontWeight: 500,
                            textDecoration: "underline",
                            textShadow: gridTheme.theme === "dark" ? '1px 1px 2px black' : ''
                        }}
                    >
                        {!isSortedByTasks ? 'Ordenar por tareas' : 'Ordenar por avance'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction='row' alignItems="flex-start" style={{ padding: '0px 50px', overflowY: "auto", overflowX: "hidden" }} >
                {
                    tasks.map((task, index) => (
                        <TaskListItem
                            key={index}
                            task={task}
                            openNoteDialog={openNoteDialog}
                            gridTheme={gridTheme}
                            color={color}
                            isLastMember={index === tasks.length - 1}
                        />
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default PendingTasks;