import { useState, useCallback } from 'react';
import _ from '@lodash';

export const useNotesForm = (initialState, onSubmit) => {
    const [form, setForm] = useState(initialState);

    const handleChange = useCallback((event) => {
        event.persist();
        setForm(form => _.setWith(_.clone({ ...form }), event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value.replace(/\n/g, ''), _.clone));

    }, []);

    const resetForm = useCallback(() => {
        if (!_.isEqual(initialState, form)) {
            setForm(initialState);
        }
    }, [form, initialState]);

    const setInForm = useCallback((name, value) => {
        setForm(form => _.setIn(form, name, value));
    }, []);

    const handleSubmit = useCallback((event) => {
        if (event) {
            event.preventDefault();
        }
        if (onSubmit) {
            onSubmit();
        }
    }, [onSubmit]);

    return {
        form,
        handleChange,
        handleSubmit,
        resetForm,
        setForm,
        setInForm
    }
}
