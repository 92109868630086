import React, { useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import NoteItemStatusRenderer from "@icarius-table/renderersAndFilters/noteItemStatusRenderer";
import NoteItemStatusFilter from "@icarius-table/renderersAndFilters/noteItemStatusFilter";
import ProgressFilter from "@icarius-table/renderersAndFilters/progressFilter";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import GridMenu from "@icarius-common/gridMenu";

const TasksGridDialogContent = (props) => {

  const {
    handleSendMail,
    data,
    dateFormat,
    isGeneralGrid,
  } = props;

  const ownGridRef = useRef();

  const messageButton = () => (
    <CustomIconButton
      title={getLocalizedString("sendMessage")}
      onClick={() => handleSendMail(ownGridRef)}
      type={"mail"}
    />
  )

  return (
    <>
      <Grid container direction="row" item xs={12} style={{ marginTop: 10, marginBottom: 10 }} justify="space-between" alignItems="center">
        <Grid container item md={12} lg={10}>
          {
            !isGeneralGrid &&
            <Typography className="whiteText" variant="h6">
              {getLocalizedString("tasks")}
            </Typography>
          }
        </Grid>
        <Grid container item md={12} lg={2}>
          <GridMenu
            customItems={[messageButton]}
          />
        </Grid>
      </Grid>
      <CommonPage
        ownGridRef={ownGridRef}
        gridTitle={getLocalizedString("tasks")}
        columnDefPage={isGeneralGrid ? paths.notes : paths.notesDialog}
        rowData={data}
        dateFormat={dateFormat}
        gridHeight={'75vh'}
        frameworkComponents={{ ProgressRenderer, ProgressFilter, NoteItemStatusRenderer, NoteItemStatusFilter }}
        ignoreProcessesbyPath
      />
    </>
  );
}

export default TasksGridDialogContent;