import React, { useMemo } from "react";
import NoteListItem from "./noteListItem";
import Masonry from 'react-masonry-css';

const NoteList = (props) => {

    const {
        searchInput,
        order,
        items,
        labels,
        openNoteDialog,
        filteredType,
        showArchived,
        color,
        groups,
        societyPeople,
        acceptNote,
        refuseNote,
    } = props;

    const reverseArr = (input) => {
        let ret = [];
        for (let i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    const checkValueInString = (textToCompare, inputValue) => {
        return textToCompare && textToCompare.toLowerCase().includes(inputValue.toLowerCase());
    }

    let notes = useMemo(() => {
        if (!items) return [];

        let notesAuxArr = [];

        if (filteredType) {
            notesAuxArr = items.filter((item) => {
                return Boolean(item.archiv === "Y") === showArchived && typeof item.labels.find(i => i.labelCode === filteredType) !== "undefined";
            })
        } else {
            notesAuxArr = items.filter((item) => Boolean(item.archiv === "Y") === showArchived);
        }

        notesAuxArr = order === "1" ? reverseArr(notesAuxArr) : notesAuxArr;

        if (notesAuxArr?.length > 0 && searchInput && searchInput !== "") {
            notesAuxArr = notesAuxArr.filter((item) => checkValueInString(item.texto, searchInput) || checkValueInString(item.titulo, searchInput))
        }

        return notesAuxArr;
    }, [items, filteredType, order, searchInput, showArchived])

    return (
        <div className="flex flex-wrap w-full">
            <Masonry
                breakpointCols={{
                    default: 5,
                    1920: 4,
                    1600: 4,
                    1366: 4,
                    1280: 4,
                    960: 2,
                    750: 1,
                    480: 1
                }}
                className="my-masonry-grid flex w-full"
                columnClassName="my-masonry-grid_column flex flex-col p-8 py-0">
                {
                    notes.map((note, index) => {
                        return (
                            <NoteListItem
                                key={index}
                                note={note}
                                color={color}
                                variateDescSize={true}
                                labels={labels}
                                openNoteDialog={openNoteDialog}
                                groups={groups}
                                societyPeople={societyPeople}
                                acceptNote={acceptNote}
                                refuseNote={refuseNote}
                            />
                        )
                    })
                }
            </Masonry>
        </div>
    )
}

export default NoteList;