import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "mui-rff";
import { Form } from "react-final-form"; 
import DialogTransition from "@icarius-common/dialogTransition";

const styles = () => ({
  cssTextAreaInput: {
      fontSize: "1.3rem",
      fontWeight: "100",
  },

  cssTitleInput: {
      fontSize: "1.5rem",
      fontWeight: "400",
  },
});

const DuplicateDialog = (props) => {

  const { open, data, handleSubmit, handleClose, classes } = props;

  const dispatch = useDispatch();

  const validateAndConfirm = (values) => {
    if(values.text && values.text !== "" && values.title && values.title !== ""){      
      handleSubmit({ ...data, texto: values.text, titulo: values.title });
      handleClose();
    }else{
      dispatch(openSnackbarAction({ msg: getLocalizedString("fieldRequeriment"), severity: "error" }));
    }
  }

  const initialValues = {
    text: "",
    title: "",
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">  
        {getLocalizedString("duplicate")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <Form
        onSubmit={validateAndConfirm}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form style={{ marginTop: -25 }} onSubmit={handleSubmit} noValidate>
            <DialogContent>
              <Grid container justify="center" alignItems="center" direction="column">
                <div className="p-8" style={{ width: "100%" }}>
                  <TextField
                      name="title"
                      margin={"none"}
                      onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, 100);
                      }}
                      placeholder={getLocalizedString("title")}
                      className="font-bold"
                      InputProps={{
                          classes: {
                              root: classes.cssTitleInput,
                          },
                      }}
                  />
                </div>
                <div className="p-8" style={{ width: "100%" }}>
                  <TextField
                    name="text"
                    margin={"none"}
                    multiline={true}
                    rows={4}
                    placeholder={getLocalizedString("writeHereYourNote")}
                    rowsMax={4}
                    InputProps={{
                        classes: {
                            root: classes.cssTextAreaInput,
                        },
                    }}
                  />
                </div>                
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
              <ButtonDialogAction type="submit" isAccept text={getLocalizedString("duplicate")} />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
}

export default withStyles(styles)(DuplicateDialog);
