import React from 'react';
import { useSelector } from "react-redux";
import { List, Typography } from '@material-ui/core';
import CheckListItemContainer from './CheckListItemContainer';
import AddCheckListItem from './AddCheckListItem';
import { getLocalizedString } from "@icarius-localization/strings";
import { getUserData } from "src/app/selectors";

const CheckList = (props) => {

    const { checklist, owner, isShared, sharedWith, onCheckListChange} = props;

    const userData = useSelector(getUserData);

    const handleListItemChange = (item) => {
        let newCheckList = [...checklist];
        newCheckList[item.index] = item;
        onCheckListChange(newCheckList);
    }

    const handleListItemRemove = (label) => onCheckListChange(checklist.filter((item) => item.label !== label));

    const handleListItemAdd = (item) => onCheckListChange([...checklist, item]);

    return (
        <div>
            <List dense>
                {
                    checklist.length > 0 ?
                    <Typography variant={"caption"} className={"whiteText"} style={{ padding: 10, fontSize: "0.85rem" }}>
                        {getLocalizedString("itemEndDateWarning")}
                    </Typography>
                    : null
                }
                {
                    checklist.map((item, index) => (
                        <CheckListItemContainer                        
                            key={index}
                            index={index}
                            item={item}
                            owner={owner}
                            isShared={isShared}
                            sharedWith={sharedWith}                            
                            handleListItemChange={handleListItemChange}
                            handleListItemRemove={handleListItemRemove}
                            
                        />
                    ))
                }
                {
                    (!owner || (owner && owner === userData?.code)) &&
                    <AddCheckListItem onListItemAdd={handleListItemAdd} />
                }
            </List>
        </div>
    );
}

export default CheckList;
